import {Column, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import i18next from 'i18next';
import HsftStore from "../../stores/baseStore";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import PartnerDropDownBoxComponent from "../../components/utvar-drop-down/PartnerDropDownBoxComponent";

const hfstStore12S = new HsftStore('TAB12S',
    {
        loadMode: "raw",
        cacheRawData: true,
        key: "s12"
    }
);
const tab12SStore = hfstStore12S.getStore();

const hfstStoreS73S = new HsftStore('TAB73S',
    {
        loadMode: "raw",
        cacheRawData: true,
        key: "x18"
    }
);
const tab73SStore = hfstStoreS73S.getStore();

const kodEditorOptions = {
    min: 1,
    max: 999,
    format: '###',
    inputAttr: {
        maxLength: 3
    }
};

function getTab36smlColumns(): Array<any> {
    return [
        <Column
            key="sml"
            dataField="sml"
            caption={i18next.t("tab36sml.cols.sml")}
            dataType="number"
            width={175}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="nazev"
            dataField="nazev"
            caption={i18next.t("tab36sml.cols.nazev")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="utv"
            dataField="utv"
            caption={i18next.t("tab13s_columns.utvar")}
            dataType="string"
            width={300}
            defaultSortOrder="asc"
            editCellComponent={UtvarDropDownBoxComponent}
        >
            <Lookup
                dataSource={tab12SStore}
                displayExpr="s12"
                valueExpr="s12"
            />
            <PatternRule
                message={i18next.t("tab13s_columns.message_utvar")}
                pattern={/^\d{1,3}$/}
            />
        </Column>,
        <Column
            key="platod"
            dataField="platod"
            caption={i18next.t("tab36sml.cols.platod")}
            dataType="date"
        />,
        <Column
            key="platdo"
            dataField="platdo"
            caption={i18next.t("tab36sml.cols.platdo")}
            dataType="date"
        />,
        <Column
            key="x18"
            dataField="x18"
            caption={i18next.t("tab36sml.cols.x18")}
            dataType="string"
            width={300}
            defaultSortOrder="asc"
            editCellComponent={PartnerDropDownBoxComponent}
        >
            <Lookup
                dataSource={tab73SStore}
                displayExpr="x18"
                valueExpr="x18"
            />
            {/*<RequiredRule/>*/}
            {/*<PatternRule*/}
            {/*    message={i18next.t("tab13s_columns.message_utvar")}*/}
            {/*    pattern={/^\d{1,3}$/}*/}
            {/*/>            */}
        </Column>,
    ];
}

export {getTab36smlColumns};
