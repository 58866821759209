import React from 'react';
import './TAB29Z.scss';
import DataSource from 'devextreme/data/data_source';
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import HsftStore from "../../stores/baseStore";
import {getTab29zColumns} from "../../definitions/columns/tab29zColumns";
import {getTab52sColumns} from "../../definitions/columns/tab52sColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import i18next from 'i18next';

function uniqueValidation(e: any) {
    if (e.isValid) {
        let id = 0;
        if ((e.oldData) && ('rowid' in e.oldData)) {
            id = e.oldData.rowid;
        }
        let updatedData: any = {};
        if (e.oldData) {
            updatedData = {...e.oldData, ...e.newData}
        } else {
            updatedData = e.newData;
        }

        for (const item of tab29zDataSource.items()) {
            if ((item.rowid !== id) && (item.sklad === updatedData.sklad)) {
                e.isValid = false;
                e.errorText = i18next.t("tab29z.error.unik_dok");
            }
        }
    }
}

const hsftStore = new HsftStore('TAB29Z');

const tab29zDataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab29zPage() {
    return (
        <ContentPage title={i18next.t("tab29z.title")} description={i18next.t("tab29z.desc")} >
            <HsftDataGrid
                id={"TAB29Z"}
                editable={true}
                columns={getTab29zColumns}
                dataSource={tab29zDataSource}
                onRowValidating={uniqueValidation}
            />
        </ContentPage>
    );
}

export default Tab29zPage;

