const appInfo = {
    title: 'DREVO',
    apiURL: process.env.REACT_APP_WS_PATH + 'api/ciselniky',
    dataURL: process.env.REACT_APP_WS_PATH + 'api/data',
    uzivateleURL: process.env.REACT_APP_WS_PATH + 'api/uzivatele',
    konfiguraceURL: process.env.REACT_APP_WS_PATH + 'api/konfigurace'
};

export default appInfo;

