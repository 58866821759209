//import {Utvar} from "../models/Utvar";
import {checkStatus,translateStatusToErrorMessage,parseJSON} from "./baseAPI"
import appInfo from '../app-info';

const url = `https://api.statistics.sk/rpo/v1/`

function convertToCIS(data: any[]): any[] {
    let cisData: any[] = data.map(convertItem);
    return cisData;
}

function convertItem(item: any): any {
    return {
        id: item.id,
        S12: item.S12,
        NAZEV: item.S12 + ' - ' +item.NAZEV
    };
}

const skRPOAPI = {
    search(key: number) {
        return fetch(`${url}search?identifier=${key}`)
            .then(checkStatus)
            .then(parseJSON)
            .catch((error: TypeError) => {
                // console.log('log client error ' + error);
                throw new Error(
                    'Při načítání dat došlo k chybě.'
                );
            });
    }
};

export { skRPOAPI };
