import React, {useRef, useMemo, useCallback, useState} from 'react';
import './TAB71T.scss';
import i18next from 'i18next';
import i18n from './../../i18n';
import {
    Column,
    ColumnChooser,
    ColumnFixing,
    DataGrid, Editing,
    FilterRow,
    HeaderFilter, Paging, RequiredRule,
    SearchPanel,
    Sorting,
    Button,
    Toolbar,
    Item,
    Export, Lookup,
    ColumnChooserSelection
} from "devextreme-react/data-grid";
import Popup, {ToolbarItem} from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import Form, {GroupItem, Label, SimpleItem, CustomRule} from "devextreme-react/form";
import AddRowButton from 'devextreme-react/button';
import validationEngine from 'devextreme/ui/validation_engine';
import GridUtils from "../../utils/gridUtils";
import HsftStore from "../../stores/baseStore";
import {FileUploader, TagBox} from "devextreme-react";
import ContentPage from "../../components/content/content-page/ContentPage";

function sortStringsConsideringCulture(value1: string, value2: string) {
    // Handling null values
    if (!value1 && value2) return -1;
    if (!value1 && !value2) return 0;
    if (value1 && !value2) return 1;
    // Determines whether two strings are equivalent in the current locale
    return value1.localeCompare(value2);
}

function notifySave() {
    notify(
        {
            message: i18n.t("tab71t.hod_ulozene"),
            width: 230,
            height: 75,
            // shading: true,
            animation: {
                show: {
                    type: 'fade', duration: 500, from: 0, to: 1,
                },
                hide: {type: 'fade', duration: 750, from: 1, to: 0},
            },
            position: {
                at: "bottom center",
                my: "bottom center",
                of: "#main-content"
            }
        },
        "success",
        2000);
}

const jlLookupDataSourceConfig: any = {
    store: {
        type: 'array',
        data: [
            {id: 1, name: i18next.t("tab71t.ihlic")},
            {id: 2, name: i18next.t("tab71t.list")}
        ],
        key: 'id'
    },
    paginate: false
}

const jlSelectBoxOptions = {
    dataSource: jlLookupDataSourceConfig,
    valueExpr: "id",
    displayExpr: "name"
};

const hsftStore = new HsftStore('TAB71T');

const tab71TDataSource = new DataSource({
    store: hsftStore.getStore()
});

const exportFormats = ['xlsx'];

const validationGroupName = "gridForm";

const hsftStoreTab57 = new HsftStore('TAB57',
    {
        loadMode: "raw",
        cacheRawData: true,
        key: "kod"
    }
);

const tab57DataSource = new DataSource({
    store: hsftStoreTab57.getStore(),
    sort: "nazev",
    langParams: {
        locale: 'cs',
        collatorOptions: {
            sensitivity: 'accent',
            caseFirst: 'upper'
        }
    }
});

const drevinyTagBoxOptions = {
    dataSource: tab57DataSource,
    valueExpr: "kod",
    displayExpr: "nazev",
    multiline: true,
    applyValueMode: "instantly",
    showSelectionControls: true
};

function Tab71TPage() {
    const gridRef = useRef<any>(null);
    const [formData, setFormData] = useState<any>({});
    const [{isNewRecord, visible}, setPopupState] = useState<any>({});
    const [fileData, setFileData] = useState<string>('');

    const showPopup = useCallback((isNewRecord: any, initFormData: any) => {
        setPopupState({isNewRecord, visible: true});
        setFormData(initFormData);
    }, []);

    const hidePopup = useCallback(() => {
        setPopupState({visible: false});
        setFormData({});
    }, []);

    const fileChanged = useCallback((e: any) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            if (reader.result && typeof reader.result === 'string')  {
                console.log(reader.result!.slice(0, 20));
                setFileData(reader!.result);
            }
            // this.setState({ fileData: reader.result });
        };

        reader.readAsDataURL(e.value![0]);

    }, []);

    const confirmChanges = useCallback(async () => {
        const result = validationEngine.validateGroup(validationGroupName);

        if (!result.isValid)
            return;

        if (isNewRecord) {
            await tab71TDataSource.store().insert(formData);
        } else {
            await tab71TDataSource.store().update(formData["rowid"], formData);
        }

        gridRef.current.instance().refresh(true);
        hidePopup();

    }, [isNewRecord, formData, hidePopup]);

    const confirmBtnOptions = useMemo(() => {
        return {
            text: i18next.t("tab71t.uloz"),
            type: "default",
            stylingMode: "contained",
            onClick: confirmChanges
        }
    }, [confirmChanges]);

    const cancelBtnOptions = useMemo(() => {
        return {
            text: i18next.t("tab71t.zrusit"),
            stylingMode: "contained",
            type: "normal",
            onClick: hidePopup
        }
    }, [hidePopup]);

    const editRow = useCallback((e: any) => {
        showPopup(false, {...e.row.data});
    }, [showPopup]);

    const addRow = useCallback(() => {
        showPopup(true, {
            sortiment: null,
            rfp: null,
            vahas: null,
            vahap: null,
            jl: 1
        });
    }, [showPopup]);

    const onExporting = useCallback((e: any) => {
        GridUtils.gridExport(e);
    }, []);

    const validateUniqueKod = useCallback((options: any) => {
        let result = true;
        let id = 0;

        if ((formData) && ('rowid' in formData)) {
            id = formData.rowid;
        }

        for (const item of tab71TDataSource.items()) {
            if ((item.rowid !== id) && (item.sortiment === options.value)) {
                result = false;
            }
        }
        return result;

    }, [formData]);

    return (
        <ContentPage title={i18next.t("tab71t.title")} description={i18next.t("tab71t.desc")} >
            <DataGrid
                ref={gridRef}
                id="grid-container"
                height={"90%"}
                dataSource={tab71TDataSource}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={true}
                remoteOperations={false}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                repaintChangesOnly={true}
                onExporting={onExporting}
            >
                <Export enabled={true} formats={exportFormats}/>
                <ColumnFixing enabled={true}/>
                <ColumnChooser enabled={true}
                               mode="select"
                               height={450}
                >
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}/>
                </ColumnChooser>
                <Sorting mode={"multiple"}/>
                <SearchPanel visible={true}/>
                <FilterRow visible={true}/>
                <HeaderFilter visible={true}/>
                <Editing
                    mode="popup"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                >
                </Editing>
                <Paging
                    enabled={false}
                />
                <Column
                    dataField="sortiment"
                    caption={i18next.t("tab71t.kod")}
                    dataType="number"
                    width={250}
                    defaultSortOrder="asc"
                >
                    <RequiredRule/>
                </Column>
                <Column
                    dataField="nazev"
                    caption={i18next.t("tab71t.nazov")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                >
                </Column>
                <Column
                    dataField="zkrn"
                    caption={i18next.t("tab71t.s_nazov")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                >
                </Column>
                <Column
                    key="jl"
                    dataField="jl"
                    caption={i18next.t("tab71t.j_l")}
                >
                    <Lookup
                        dataSource={jlLookupDataSourceConfig}
                        valueExpr="id"
                        displayExpr="name"
                    />
                    <RequiredRule/>
                </Column>,
                <Column
                    dataField="dreviny_zkr"
                    caption={i18next.t("tab71t.dreviny")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                >
                </Column>
                <Column
                    dataField="rfp"
                    caption={i18next.t("tab71t.red_faktor")}
                    dataType="number"
                >
                </Column>
                <Column
                    dataField="vahas"
                    caption={i18next.t("tab71t.vaha_sur")}
                    dataType="number"
                >
                </Column>
                <Column
                    dataField="vahap"
                    caption={i18next.t("tab71t.vaha_pr")}
                    dataType="number"
                >
                </Column>
                <Column
                    dataField="popis"
                    caption={i18next.t("tab71t.popis")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                    visible={false}
                >
                </Column>
                <Column
                    type="buttons"
                    allowHiding={false}
                    showInColumnChooser={false}
                    visible={true}
                >
                    <Button name="edit" onClick={editRow}/>
                    <Button name="delete"/>
                </Column>
                <Toolbar>
                    <Item location="after">
                        <AddRowButton
                            icon="plus"
                            onClick={addRow}/>
                    </Item>
                    <Item name="columnChooserButton"/>
                    <Item name="exportButton"/>
                    <Item name="searchPanel"/>
                </Toolbar>
            </DataGrid>
            {visible ? (
                <Popup
                    title={isNewRecord ? i18next.t("tab71t.new_sort") : i18next.t("tab71t.edit_sort")}
                    hideOnOutsideClick={true}
                    visible={true}
                    height={"auto"}
                    onHiding={hidePopup}
                >
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={confirmBtnOptions}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={cancelBtnOptions}
                    />
                    <Form
                        formData={formData}
                        colCount={2}
                        validationGroup={validationGroupName}
                    >

                        <SimpleItem
                            dataField="sortiment"
                            editorType="dxNumberBox"
                        >
                            <Label text="Kód"/>
                            <CustomRule message={i18next.t("tab71t.kod_unik")}
                                        validationCallback={validateUniqueKod}/>
                        </SimpleItem>
                        <SimpleItem dataField="nazev" isRequired={true}>
                            <Label text={i18next.t("tab71t.nazov")}/>
                        </SimpleItem>
                        <SimpleItem dataField="zkrn" isRequired={true}>
                            <Label text={i18next.t("tab71t.s_nazov")}/>
                        </SimpleItem>
                        <SimpleItem
                            dataField="jl"
                            editorType="dxSelectBox"
                            editorOptions= {jlSelectBoxOptions}
                        >
                            <Label text={i18next.t("tab71t.j_l")}/>
                        </SimpleItem>
                        <SimpleItem dataField="rfp" editorType="dxNumberBox">
                            <Label text={i18next.t("tab71t.red_faktor")}/>
                        </SimpleItem>
                        <SimpleItem dataField="vahas" editorType="dxNumberBox">
                            <Label text={i18next.t("tab71t.vaha_sur")}/>
                        </SimpleItem>
                        <SimpleItem dataField="vahap" editorType="dxNumberBox">
                            <Label text={i18next.t("tab71t.vaha_pr")}/>
                        </SimpleItem>

                        <SimpleItem
                            dataField="dreviny"
                            editorType="dxTagBox"
                            editorOptions= {drevinyTagBoxOptions}
                        >
                            <Label text={i18next.t("tab71t.dreviny")}/>
                        </SimpleItem>
                    </Form>
                </Popup>
            ) : null}
        </ContentPage>
    );
}

export default Tab71TPage;
