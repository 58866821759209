import React, {Fragment, useState, useEffect, useCallback} from 'react';
import './preferences.scss';
import {
    Form, Label,
    SimpleItem,
    GroupItem,
    ButtonItem

} from 'devextreme-react/form';
import {Config} from '../../models/Config';
import Switch, {SwitchTypes} from 'devextreme-react/switch';
import {ButtonStyle, ButtonType} from "devextreme/common";
// import {configAPI} from "../../api/configAPI";
import notify from 'devextreme/ui/notify';
import {LoadPanel} from 'devextreme-react/load-panel';
import ContentPage from "../../components/content/content-page/ContentPage";
import {createCatalogAPI} from "../../api/catalogAPI";
import i18next from 'i18next';

const baseConfig: Config = {
    use_useky: false,
    use_sklady: false,
    use_podvykony: false,
    use_prostriedky: false,
    use_certifikace: false,
    use_cislokmhr: false,
    use_cislokmhr_522_cislovani123: false,
    use_akost: false,
    use_hrubkovystupen: false,
    use_jprl: false,
    use_pracovniky: false,
}

const configAPI = createCatalogAPI("konfigurace");

const loadPanelPosition = {of: '#main-content'};

const submitButtonOptions: { text: string, useSubmitBehavior: boolean, type: ButtonType, stylingMode: ButtonStyle } = {
    text: "Uložiť",
    useSubmitBehavior: true,
    type: "default",
    stylingMode: "contained"
};

function PreferencesForm() {
    const [config, setConfig] = useState<Config>(baseConfig);
    // const [mujConfig, setMujConfig] = useState<Config>(baseConfig);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);


    useEffect(() => {
        async function loadConfig() {
            setLoading(true);
            try {
                const data = await configAPI.load();
                if (data.data.length === 1) {
                    setConfig(data.data[0]);
                }

            } catch (e) {
                if (e instanceof Error) {

                }
            } finally {
                setLoading(false);
            }
        }

        loadConfig();
    }, []);

    // const onCDREVValueChanged = useCallback((e: any) => {
    //     if (e.value) {
    //         if (!setting) {
    //             setSetting(true);
    //             setConfig({...config, USE_CDREV: true});
    //             setSetting(false);
    //         }
    //         setJPRLDisabled(false);
    //         setTAB80VDisabled(false);
    //     } else {
    //         if (!setting) {
    //             setSetting(true);
    //             setConfig({...config, USE_CDREV: false, USE_JPRL: false, USE_TAB80V: false});
    //             setSetting(false);
    //         }
    //         setJPRLDisabled(true);
    //         setTAB80VDisabled(true);
    //     }
    // }, [setting, config]);
    //
    // const onODVValueChanged = useCallback((e: any) => {
    //     if (e.value) {
    //         if (!setting) {
    //             setSetting(true);
    //             setConfig({...config, USE_ODV: true});
    //             setSetting(false);
    //         }
    //         setCERTDisabled(false);
    //     } else {
    //         if (!setting) {
    //             setSetting(true);
    //             setConfig({...config, USE_ODV: false, USE_CERT: false});
    //             setSetting(false);
    //         }
    //         setCERTDisabled(true);
    //     }
    // }, [setting, config]);
    //
    // const onSwitchValueChanged = useCallback((name: string, e: any) => {
    //     if (!setting && e.event) {
    //         const values: any = {};
    //         values[name] = e.value;
    //         setConfig({...config, ...values});
    //     }
    // }, [setting, config]);

    const handleSubmit = useCallback((e: any) => {
        async function saveConfig() {
            try {
                setSaving(true);
                let values: any = config;
                // values.id = 1;
                await configAPI.update("1", values);
                setSaving(false);

                notify(
                    {
                        message: i18next.t("pref.ulozene"),
                        width: 230,
                        height: 75,
                        // shading: true,
                        animation: {
                            show: {
                                type: 'fade', duration: 500, from: 0, to: 1,
                            },
                            hide: {type: 'fade', duration: 750, from: 1, to: 0},
                        },
                        position: {
                            at: "bottom center",
                            my: "bottom center",
                            of: "#main-content"
                        }
                    },
                    "success",
                    2000);
            } catch (e) {
                if (e instanceof Error) {

                }
            } finally {

            }
        }

        saveConfig();
        e.preventDefault();
    }, [config]);

    return (
        <Fragment>
            <form action="/preferences" onSubmit={handleSubmit}>
                <LoadPanel
                    position={loadPanelPosition}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={loading}
                    shading={true}
                    showIndicator={true}
                    showPane={true}
                    hideOnOutsideClick={false}

                />
                <LoadPanel
                    position={loadPanelPosition}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={saving}
                    shading={true}
                    showIndicator={true}
                    showPane={true}
                    message={i18next.t("pref.ukld")}
                    hideOnOutsideClick={false}

                />
                <Form
                    formData={config}
                    labelLocation={"left"}
                    colCount={1}

                    id="form"
                >
                    <GroupItem caption={i18next.t("pref.zak_nast")}>
                        <SimpleItem
                            dataField={"use_useky"} editorType={"dxSwitch"}
                        >
                            {/*<Switch value={config.USE_TAB13S}/>*/}
                            <Label text={i18next.t("pref.use_strediska")}/>
                        </SimpleItem>
                        <SimpleItem dataField={"use_sklady"} editorType={"dxSwitch"}>
                            {/*<Switch value={config.USE_SKL}/>*/}
                            <Label text={i18next.t("pref.use_sklady")}/>
                        </SimpleItem>
                        <SimpleItem dataField={"use_podvykony"} editorType={"dxSwitch"}>
                            {/*<Switch value={config.USE_TAB31S}/>*/}
                            <Label text={i18next.t("pref.use_vyk")}/>
                        </SimpleItem>
                        <SimpleItem dataField={"use_prostriedky"} editorType={"dxSwitch"}>
                            {/*<Switch value={config.USE_TAB33S}/>*/}
                            <Label text={i18next.t("pref.use_ucely")}/>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={i18next.t("pref.odvoz_vyk")}>
                        <SimpleItem
                            dataField={"use_certifikace"} editorType={"dxSwitch"}
                        >
                            {/*<Switch value={config.USE_TAB13S}/>*/}
                            <Label text={i18next.t("pref.use_cert")}/>
                        </SimpleItem>
                        <SimpleItem dataField={"use_cislokmhr"} editorType={"dxSwitch"}>
                            {/*<Switch value={config.USE_SKL}/>*/}
                            <Label text={i18next.t("pref.cisl_kus")}/>
                        </SimpleItem>
                        <SimpleItem dataField={"use_cislokmhr_522_cislovani123"} editorType={"dxSwitch"}>
                            {/*<Switch value={config.USE_TAB31S}/>*/}
                            <Label text={i18next.t("pref.cisl_od_jed")}/>
                        </SimpleItem>
                        <SimpleItem dataField={"use_akost"} editorType={"dxSwitch"}>
                            {/*<Switch value={config.USE_TAB33S}/>*/}
                            <Label text={i18next.t("pref.use_akost")}/>
                        </SimpleItem>
                        <SimpleItem dataField={"use_hrubkovystupen"} editorType={"dxSwitch"}>
                            {/*<Switch value={config.USE_TAB33S}/>*/}
                            <Label text={i18next.t("pref.use_hr_st")}/>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={i18next.t("pref.cis_dr")}>
                        <SimpleItem
                            dataField={"use_jprl"} editorType={"dxSwitch"}
                        >
                            {/*<Switch value={config.USE_TAB13S}/>*/}
                            <Label text={i18next.t("pref.use_jprl")}/>
                        </SimpleItem>
                        <SimpleItem dataField={"use_pracovniky"} editorType={"dxSwitch"}>
                            {/*<Switch value={config.USE_SKL}/>*/}
                            <Label text={i18next.t("pref.use_prac")}/>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem cssClass="buttons-group" colCount={2}>
                        <ButtonItem buttonOptions={submitButtonOptions}/>
                    </GroupItem>
                </Form>
            </form>
        </Fragment>);
}

export default () => (
    <ContentPage title={i18next.t("pref.title")}
                 description={i18next.t("pref.desc")}>
        <PreferencesForm></PreferencesForm>
    </ContentPage>
);
