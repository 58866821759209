import React from 'react';
import './TAB80V.scss';
import i18next from 'i18next';

import DataSource from 'devextreme/data/data_source';
import HsftStore from "../../stores/baseStore";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab80vColumns} from "../../definitions/columns/tab80vColumns";
import ContentPage from "../../components/content/content-page/ContentPage";

function uniqueValidation(e: any) {
    if (e.isValid) {
        let id = '';
        if ((e.oldData) && ('rowid' in e.oldData)) {
            id = e.oldData.rowid;
        }
        let updatedData: any = {};
        if (e.oldData) {
            updatedData = {...e.oldData, ...e.newData}
        } else {
            updatedData = e.newData;
        }

        for (const item of tab80VDataSource.items()) {
            if ((item.rowid !== id) && (item.cis === updatedData.cis)) {
                e.isValid = false;
                e.errorText = "Číslo pracovníka musí být unikátní v rámci číselníku.";
            }
        }
    }
}

const hsftStore = new HsftStore('TAB80V');

const tab80VDataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab80VPage() {

    return (
        <ContentPage title={i18next.t("tab80v.title")} description={i18next.t("tab80v.desc")} >
            <HsftDataGrid
                id={"TAB80V"}
                editable={true}
                columns={getTab80vColumns}
                dataSource={tab80VDataSource}
                onRowValidating={uniqueValidation}
            />
        </ContentPage>
    );
}
export default Tab80VPage;
