import React from 'react';
import './TAB79RZ.scss';
import HsftStore from "../../stores/baseStore";
import DataSource from "devextreme/data/data_source";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab79RZColumns} from "../../definitions/columns/tab79rzColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import {getTab57Columns} from "../../definitions/columns/tab57Columns";
import i18next from 'i18next';

const hsftStore = new HsftStore('TAB79RZ');
const tab79RZDataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab79RZPage() {

    return (
        <ContentPage title={i18next.t("tab79rz.title")} description={i18next.t("tab79rz.desc")} >
            <HsftDataGrid
                id={"TAB79RZ"}
                editable={true}
                columns={getTab79RZColumns}
                dataSource={tab79RZDataSource}
            />
        </ContentPage>
    );
}

export default Tab79RZPage;
