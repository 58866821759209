import {Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";
import i18next from 'i18next';

function getTab10Columns(): Array<any> {
    return [
        <Column
            key="typ"
            dataField="typ"
            caption={i18next.t("tab10_columns.typ")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
            allowEditing={false}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="nazev"
            dataField="nazev"
            caption={i18next.t("tab10_columns.nazev")}
            dataType="string"
            allowEditing={false}
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="zkr"
            dataField="zkr"
            caption={i18next.t("tab10_columns.zkr")}
            dataType="string"
            width={180}
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="pov"
            dataField="pov"
            caption={i18next.t("tab10_columns.pov")}
            dataType="boolean"
            width={125}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="delka_od"
            dataField="delka_od"
            caption={i18next.t("tab10_columns.delka_od")}
            dataType="number"
            width={150}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="delka_do"
            dataField="delka_do"
            caption={i18next.t("tab10_columns.delka_do")}
            dataType="number"
            width={150}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="prum_od"
            dataField="prum_od"
            caption={i18next.t("tab10_columns.prum_od")}
            dataType="number"
            width={150}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="prum_do"
            dataField="prum_do"
            caption={i18next.t("tab10_columns.prum_do")}
            dataType="number"
            width={150}
        >
            <RequiredRule/>
        </Column>,
    ];
}

export {getTab10Columns};
