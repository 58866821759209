import CustomStore from "devextreme/data/custom_store";
import {tab13sAPI} from "../api/tab13sAPI";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import {tab12sAPI} from "../api/tab12sAPI";
import {tab31sAPI} from "../api/tab31sAPI";
import {useAuth} from "../contexts/auth";
import {createCatalogAPI} from "../api/catalogAPI";
import i18next from 'i18next';

export default class HsftStore {
    // private dataSource: any;
    private store: any;
    // private loadMode

    constructor(catalogName: string, options: {} = {}) {

        let api: any = this.getApi(catalogName);

        if (api === null) {
            throw 'Neexistující API.';
        } else {
            this.store = new CustomStore({
                // onLoading: function (loadOptions) {
                //     console.log(loadOptions);
                // },
                key: 'rowid',
                // key: (enumerationName === 'tab31s' ? 'rowid' : 'id'),
                load: async (loadOptions) => {
                    let data = await api.load(loadOptions);
                    return data.data;
                },
                insert: async (values) => {
                    return await api.insert(values).then(this.notifySave);
                },
                update: async (key, values) => {
                    return await api.update(key, values).then(this.notifySave);
                },
                remove: async (key) => await api.remove(key),
                ...options
            });
        }
    }

    public getStore(): any {
        return this.store;
    }

    private notifySave() {
        notify(
            {
                message: i18next.t("baseStore.notifySave"),
                width: 230,
                height: 75,
                // shading: true,
                animation: {
                    show: {
                        type: 'fade', duration: 500, from: 0, to: 1,
                    },
                    hide: {type: 'fade', duration: 750, from: 1, to: 0},
                },
                position: {
                    at: "bottom center",
                    my: "bottom center",
                    of: "#main-content"
                }
            },
            "success",
            2000);
    }

    private getApi(catalogName: string) {
        // Použití: Vytvoření instance API pro konkrétní číselník
        return createCatalogAPI(catalogName);
    }
}






