import React from 'react';
import './tab31s.scss';
import DataSource from 'devextreme/data/data_source';
import HsftStore from "../../stores/baseStore";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab31sColumns} from "../../definitions/columns/tab31sColumns";
import {getTab12sColumns} from "../../definitions/columns/tab12sColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import i18next from 'i18next';

function uniqueValidation(e: any) {
    if (e.isValid) {
        let id = '';
        if ((e.oldData) && ('rowid' in e.oldData)) {
            id = e.oldData.rowid;
        }
        let updatedData: any = {};
        if (e.oldData) {
            updatedData = {...e.oldData, ...e.newData}
        } else {
            updatedData = e.newData;
        }

        for (const item of tab31SDataSource.items()) {
            if ((item.rowid != id) && (item.kod === updatedData.kod)) {
                e.isValid = false;
                e.errorText = i18next.t("tab31s.error.unik_kod");
            }
        }
    }
}

const hsftStore = new HsftStore('TAB31S');

const tab31SDataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab31sPage() {

    return (
        <ContentPage title={i18next.t("tab31s.title")} description={i18next.t("tab31s.desc")} >
            <HsftDataGrid
                id={"TAB31S"}
                editable={true}
                columns={getTab31sColumns}
                dataSource={tab31SDataSource}
                onRowValidating={uniqueValidation}
            />
        </ContentPage>
    );
}

export default Tab31sPage;
