import type { SEIWINUser } from '../types';
import i18next from 'i18next';

export async function signIn(userName: string, password: string) {
    try {
        // Send request

         let response = await fetch(process.env.REACT_APP_WS_PATH + `api/login`, {
            method: 'POST',
            body: JSON.stringify({
                username: userName,
                password: password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            let responseData:SEIWINUser = await response.json();

            return {
                isOk: true,
                data: {
                    userName: userName,
                    token: responseData.token,
                    desc: responseData.popis,
                    firstName: responseData.jmeno,
                    lastName: responseData.prijmeni,
                    email: responseData.email
                }
            };
        } else {
            return {
                isOk: false,
                message: i18next.t("login_form.login_fail")
            };
        }

    } catch {
        return {
            isOk: false,
            message: i18next.t("login_form.login_fail")
        };
    }
}

export async function getUser(token:string) {
    try {
        let response = await fetch(process.env.REACT_APP_WS_PATH + `api/getuser`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (response.ok) {
            let responseData:SEIWINUser = await response.json();

            return {
                isOk: true,
                data: {
                    userName: '',
                    token: token,
                    desc: responseData.popis,
                    firstName: responseData.jmeno,
                    lastName: responseData.prijmeni,
                    email: responseData.email
                }
            };
        } else {
            return {
                isOk: false,
                message: "Přihlášení se nepodařilo."
            };
        }
    } catch {
        return {
            isOk: false
        };
    }
}

export async function createAccount(email: string, password: string) {
    try {
        // Send request

        return {
            isOk: true
        };
    } catch {
        return {
            isOk: false,
            message: "Failed to create account"
        };
    }
}

export async function changePassword(email: string, recoveryCode?: string) {
    try {
        // Send request
        return {
            isOk: true
        };
    } catch {
        return {
            isOk: false,
            message: "Failed to change password"
        }
    }
}

export async function resetPassword(email: string) {
    try {
        // Send request

        return {
            isOk: true
        };
    } catch {
        return {
            isOk: false,
            message: "Failed to reset password"
        };
    }
}
