import { useNavigation } from '../contexts/navigation';
import {createBrowserRouter} from "react-router-dom";
import {SingleCard} from "../layouts";
import {LoginForm} from "../components";


function translateStatusToErrorMessage(status: number) {
    switch (status) {
        case 401:
            return 'Nejste přihlášen. Přihlašte se prosím znovu.';
        case 403:
            return 'Nemáte oprávnění na provedení příslušné akce.';
        default:
            return 'Nastal problém s načítáním dat.';
    }
}

async function checkStatus(response: any) {

    if (response.ok) {
        return response;
    } else {

        const httpErrorInfo = {
            status: response.status,
            statusText: response.statusText,
            url: response.url,
        };

        try {
            const errorData = await response.json();
            const errorMessage = errorData.message || translateStatusToErrorMessage(httpErrorInfo.status);

            if (errorMessage.startsWith("Neplatný nebo prošlý autorizační token")) {
                // smažu token a redirectnu uživatele
                localStorage.removeItem('token');
                window.location.href = '/';
            }

            throw new Error(errorMessage);
        } catch (jsonError) {

            let errorMessage = translateStatusToErrorMessage(httpErrorInfo.status);
            throw new Error(errorMessage);
        }
    }
}

function parseJSON(response: Response) {
    return response.json();
}

export {translateStatusToErrorMessage, checkStatus, parseJSON };
