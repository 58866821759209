import {
    HomePage,
    Tab12sPage,
    Tab13sPage,
    Tab31sPage,
    PreferencesPage,
    CiselnikyPage,
    Tab73sPage, TAB29ZPage, TAB33SPage, TAB57Page, TAB71TPage, TAB79RZPage, TAB80VPage, TABCERPage, TABORGPage, TABTLSPage, TABTSPage, TAB52Page, TAB10Page, UsersPage, TAB36SMLPage, DocumentsPage
} from './pages';
import {withNavigationWatcher} from './contexts/navigation';

const routes = [
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/tab12s',
        element: Tab12sPage
    },
    {
        path: '/tab13s',
        element: Tab13sPage
    },
    {
        path: '/tab31s',
        element: Tab31sPage
    },
    {
        path: '/preferences',
        element: PreferencesPage
    },
    {
        path: '/ciselniky',
        element: CiselnikyPage
    },
    {
        path: '/tab73s',
        element: Tab73sPage
    },
  {
    path: '/tab29z',
    element: TAB29ZPage
  },
  {
    path: '/tab33s',
    element: TAB33SPage
  },
  {
    path: '/tab57',
    element: TAB57Page
  },
  {
    path: '/tab71t',
    element: TAB71TPage
  },
  {
    path: '/tab79rz',
    element: TAB79RZPage
  },
  {
    path: '/tab80v',
    element: TAB80VPage
  },
  {
    path: '/tabcer',
    element: TABCERPage
  },
  {
    path: '/taborg',
    element: TABORGPage
  },
  {
    path: '/tabtls',
    element: TABTLSPage
  },
  {
    path: '/tabts',
    element: TABTSPage
  },
  {
    path: '/tab52',
    element: TAB52Page
  },
  {
    path: '/tab10',
    element: TAB10Page
  },
  {
    path: '/users',
    element: UsersPage
  }, 
  {
    path: '/tab36-sml',
    element: TAB36SMLPage
  }, 
  {
    path: '/documents',
    element: DocumentsPage
  }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
