import React from 'react';
import './TABTLS.scss';
import DataSource from 'devextreme/data/data_source';
import HsftStore from "../../stores/baseStore";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTabtlsColumns} from "../../definitions/columns/tabtlsColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import i18next from 'i18next';

function uniqueValidation(e: any) {
    if (e.isValid) {
        let id = '';
        if ((e.oldData) && ('rowid' in e.oldData)) {
            id = e.oldData.rowid;
        }
        let updatedData: any = {};
        if (e.oldData) {
            updatedData = {...e.oldData, ...e.newData}
        } else {
            updatedData = e.newData;
        }

        for (const item of tabtlsDataSource.items()) {
            if ((item.rowid !== id)
                && (item.jakost === updatedData.jakost)
                && (item.prum_od === updatedData.prum_od)
                && (item.prum_do === updatedData.prum_do)
                && (item.tl_st === updatedData.tl_st)
            ) {
                e.isValid = false;
                e.errorText = i18next.t("tabtls.error.unik_hod");
            }
        }
        if (e.isValid) {
            if (updatedData.prum_od > updatedData.prum_do) {
                e.isValid = false;
                e.errorText = i18next.t("tabtls.error.prm_mensi");
            }
        }
    }

}

const hsftStore = new HsftStore('TABTLS');

const tabtlsDataSource = new DataSource({
    store: hsftStore.getStore()
});

function TabtlsPage() {

    return (
        <ContentPage title={i18next.t("tabtls.title")} description={i18next.t("tabtls.desc")} >
            <HsftDataGrid
                id={"TABTLS"}
                editable={true}
                columns={getTabtlsColumns}
                dataSource={tabtlsDataSource}
                onRowValidating={uniqueValidation}
            />
        </ContentPage>
    );
}

export default TabtlsPage;
