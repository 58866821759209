import {Button, Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import i18next from 'i18next';
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";

function getDocumentDetailsColumns(): Array<any> {
    return [
        <Column
            key="v30"
            dataField="v30"
            caption={i18next.t("doklady.cols_d.v30")}
            dataType="number"
        />,
        <Column
            key="k71"
            dataField="k71"
            caption={i18next.t("doklady.cols_d.k71")}
            dataType="number"
        />,
        <Column
            key="k56"
            dataField="k56"
            caption={i18next.t("doklady.cols_d.k56")}
            dataType="number"
        />,
        <Column
            key="n57"
            dataField="n57"
            caption={i18next.t("doklady.cols_d.n57")}
            dataType="string"
        />,
        <Column
            key="d71"
            dataField="d71"
            caption={i18next.t("doklady.cols_d.d71")}
            dataType="string"
        />,
        <Column
            key="d71_nazev"
            dataField="d71_nazev"
            caption={i18next.t("doklady.cols_d.d71_nazev")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="k73"
            dataField="k73"
            caption={i18next.t("doklady.cols_d.k73")}
            dataType="number"
        />,
        <Column
            key="k75"
            dataField="k75"
            caption={i18next.t("doklady.cols_d.k75")}
            dataType="number"
        />,
        <Column
            key="k76"
            dataField="k76"
            caption={i18next.t("doklady.cols_d.k76")}
            dataType="number"
        />,
        <Column
            key="k68"
            dataField="k68"
            caption={i18next.t("doklady.cols_d.k68")}
            dataType="number"
        />,
        <Column
            key="r62"
            dataField="r62"
            caption={i18next.t("doklady.cols_d.r62")}
            format={{type: 'fixedPoint', precision: 2}}
            dataType="number"
        />,

    ];
}

export {getDocumentDetailsColumns};
