import {Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";
import i18next from 'i18next';

function getTabtsColumns(): Array<any> {
    return [
        <Column
            key="ts"
            dataField="ts"
            caption={i18next.t("tabts_columns.ts")}
            dataType="string"
            width={150}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="prum_od"
            dataField="prum_od"
            caption={i18next.t("tabts_columns.prum_od")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="prum_do"
            dataField="prum_do"
            caption={i18next.t("tabts_columns.prum_do")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="popis"
            dataField="popis"
            caption={i18next.t("tabts_columns.popis")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>,
    ];
}

export {getTabtsColumns};
