import React, {useRef, useMemo, useCallback, useState} from 'react';
import './tab73s.scss';
import i18next from 'i18next';
import i18n from './../../i18n';
import {
    Column,
    ColumnChooser,
    ColumnFixing,
    DataGrid, Editing,
    FilterRow,
    HeaderFilter, Paging, RequiredRule,
    SearchPanel,
    Sorting,
    Button,
    Toolbar,
    Item,
    Export, ColumnChooserSelection, PatternRule
} from "devextreme-react/data-grid";
import DropDownButton, {} from 'devextreme-react/drop-down-button';
import Popup, {ToolbarItem} from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";
import {skRPOAPI} from "../../api/skRPO";
import {czARESAPI} from "../../api/czARES";
import DataSource from "devextreme/data/data_source";
import Form, {GroupItem, Label, SimpleItem, CustomRule} from "devextreme-react/form";
import AddRowButton from 'devextreme-react/button';
import validationEngine from 'devextreme/ui/validation_engine';
import GridUtils from "../../utils/gridUtils";
import HsftStore from "../../stores/baseStore";
import ContentPage from "../../components/content/content-page/ContentPage";

function sortStringsConsideringCulture(value1: string, value2: string) {
    // Handling null values
    if (!value1 && value2) return -1;
    if (!value1 && !value2) return 0;
    if (value1 && !value2) return 1;
    // Determines whether two strings are equivalent in the current locale
    return value1.localeCompare(value2);
}

function notifySave() {
    notify(
        {
            message: i18n.t("tab73s.hod_ulozene"),
            width: 230,
            height: 75,
            // shading: true,
            animation: {
                show: {
                    type: 'fade', duration: 500, from: 0, to: 1,
                },
                hide: {type: 'fade', duration: 750, from: 1, to: 0},
            },
            position: {
                at: "bottom center",
                my: "bottom center",
                of: "#main-content"
            }
        },
        "success",
        2000);
}

const hsftStore = new HsftStore('TAB73S');

const tab73SDataSource = new DataSource({
    store: hsftStore.getStore()
});

const exportFormats = ['xlsx'];

const buttonActions = [
    {id: 1, text: i18next.t("tab73s.nacitat_udaje_ares")},
];

const pscEditorOptions = {
    maxLength: 5,
    mask: "99999",
    useMaskedValue: false,
    showMaskMode: 'onFocus'
};

// remove spaces after paste
const numberBoxEditorOptions = {
    min:1,
    max:9999999999999,
    format: '#',
    // onPaste: function(e: any){
    //     setTimeout(()=>{
    //         let currentValue = e.component.option("text").replace(/\s/g, '');
    //         e.component.option("value", currentValue)
    //     })
    // }
};

const validationGroupName = "gridForm";

function Tab73sPage() {
    const gridRef = useRef<any>(null);
    const [formData, setFormData] = useState<any>({});
    const [{isNewRecord, visible}, setPopupState] = useState<any>({});

    const showPopup = useCallback((isNewRecord: any, initFormData: any) => {
        setPopupState({isNewRecord, visible: true});
        if (initFormData && initFormData.psc !== null && initFormData.psc !== undefined) {
            initFormData.psc = initFormData.psc.toString();
        }
        setFormData(initFormData);
    }, []);

    const hidePopup = useCallback(() => {
        setPopupState({visible: false});
        setFormData({});
    }, []);

    const confirmChanges = useCallback(async () => {
        const result = validationEngine.validateGroup(validationGroupName);

        if (!result.isValid)
            return;

        if (isNewRecord) {
            await tab73SDataSource.store().insert(formData);
        } else {
            await tab73SDataSource.store().update(formData["rowid"], formData);
        }

        gridRef.current.instance().refresh(true);
        hidePopup();

    }, [isNewRecord, formData, hidePopup]);

    const confirmBtnOptions = useMemo(() => {
        return {
            text: i18next.t("tab73s.uloz"),
            type: "default",
            stylingMode: "contained",
            onClick: confirmChanges
        }
    }, [confirmChanges]);

    const cancelBtnOptions = useMemo(() => {
        return {
            text: i18next.t("tab73s.zrusit"),
            stylingMode: "contained",
            type: "normal",
            onClick: hidePopup
        }
    }, [hidePopup]);

    const editRow = useCallback((e: any) => {
        showPopup(false, {...e.row.data});
    }, [showPopup]);

    const addRow = useCallback(() => {
        showPopup(true, {
            s73: null,
            x18: null
        });
    }, [showPopup]);

    const onExporting = useCallback((e: any) => {
        GridUtils.gridExport(e);
    }, []);

    const loadDataROP = useCallback(() => {
        interface Address {
            street: string;
            buildingNumber: string;
            municipality: { value: string };
            country: { value: string };
            postalCodes: string[];
        }

        interface FullName {
            value: string;
        }

        interface Result {
            addresses: Address[];
            fullNames: FullName[];
        }

        interface Data {
            results: Result[];
        }

        let newData: { [key: string]: any } = {};

        async function loadRPOData() {
            try {
                const data: Data = await skRPOAPI.search(Number(formData['s73']));
                if (data && 'results' in data && data.results.length > 0) {
                    const result = data.results[0];
                    if (result.addresses.length > 0) {
                        let address = result.addresses[0];
                        newData['ulice'] = address.street + ' ' + address.buildingNumber;
                        newData['mesto'] = address.municipality.value;
                        newData['stat'] = address.country.value;
                        newData['psc'] = address.postalCodes[0];
                    }

                    if (result.fullNames.length > 0) {
                        newData['nazev1'] = result.fullNames[0].value;
                    }
                }
                setFormData({...formData, ...newData});

            } catch (e) {
                if (e instanceof Error) {

                }
            } finally {

            }
        }

        loadRPOData().catch(() => {
            throw new Error(
                i18next.t("tab73s.error.load_rpo")
            );
        });
    }, [formData]);


    const loadDataItem = useCallback(() => {
        interface Sidlo {
            nazevUlice?: string;
            cisloDomovni?: string;
            cisloOrientacni?: string;
            nazevObce: string;
            nazevStatu: string;
            psc: string;
        }

        interface Data {
            sidlo: Sidlo;
            obchodniJmeno: string;
            dic: string;
        }

        let newData: { [key: string]: any } = {};

        async function loadARESData() {
            try {
                const data: Data = await czARESAPI.search(Number(formData['s73']));

                if (data) {
                    if (data.sidlo?.nazevUlice) {
                        newData['ulice'] = data.sidlo.nazevUlice;
                        if (data.sidlo.cisloDomovni && data.sidlo.cisloOrientacni) {
                            newData['ulice'] += ' ' + data.sidlo.cisloDomovni + '/' + data.sidlo.cisloOrientacni;
                        } else if (data.sidlo.cisloOrientacni) {
                            newData['ulice'] += ' ' + data.sidlo.cisloOrientacni;
                        }
                    }

                    newData['mesto'] = data.sidlo.nazevObce;
                    newData['stat'] = data.sidlo.nazevStatu;
                    newData['psc'] = data.sidlo.psc;

                    newData['nazev1'] = data.obchodniJmeno;
                    newData['dic'] = data.dic;
                }

                setFormData({...formData, ...newData});

            } catch (e) {
                if (e instanceof Error) {

                }
            } finally {

            }
        }

        loadARESData().catch(() => {
            throw new Error(
                i18next.t("tab73s.error.load_ares")
            );
        });
    }, [formData]);

    const validateUniqueS73 = useCallback((options: any) => {
        let result = true;
        let id = 0;

        if ((formData) && ('rowid' in formData)) {
            id = formData.rowid;
        }

        for (const item of tab73SDataSource.items()) {
            if ((item.rowid !== id) && (item.s73 === options.value)) {
                result = false;
            }
        }
        return result;

    }, [formData]);

    return (
        <ContentPage title={i18next.t("tab73s.title")} description={i18next.t("tab73s.desc")} >
            <DataGrid
                ref={gridRef}
                id="grid-container"
                dataSource={tab73SDataSource}
                height={"100%"}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={true}
                remoteOperations={false}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                repaintChangesOnly={true}
                onExporting={onExporting}
            >
                <Export enabled={true} formats={exportFormats}/>
                <ColumnFixing enabled={true}/>
                <ColumnChooser enabled={true}
                               mode="select"
                               height={450}
                >
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}/>
                </ColumnChooser>
                <Sorting mode={"multiple"}/>
                <SearchPanel visible={true}/>
                <FilterRow visible={true}/>
                <HeaderFilter visible={true}/>
                <Editing
                    mode="popup"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                >
                </Editing>
                <Paging
                    enabled={false}
                />
                <Column
                    key="x18"
                    dataField="x18"
                    caption={i18next.t("tab73s.c_partnera")}
                    dataType="number"
                    width={250}
                    defaultSortOrder="asc"
                >
                    <RequiredRule/>
                </Column>
                <Column
                    key="nazev1"
                    dataField="nazev1"
                    caption={i18next.t("tab73s.nazov")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                >
                    <RequiredRule/>
                </Column>
                <Column
                    key="nazev2"
                    dataField="nazev2"
                    caption={i18next.t("tab73s.nazov2")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                    visible={false}
                >
                </Column>
                <Column
                    key="s73"
                    dataField="s73"
                    caption={i18next.t("tab73s.ico")}
                    dataType="string"
                >
                </Column>
                <Column
                    key="dic"
                    dataField="dic"
                    caption={i18next.t("tab73s.dic")}
                    dataType="string"
                >
                </Column>
                <Column
                    key="sk_dic"
                    dataField="sk_dic"
                    caption={i18next.t("tab73s.dic_sk")}
                    dataType="string"
                >
                </Column>
                <Column
                    key="ulice"
                    dataField="ulice"
                    caption={i18next.t("tab73s.ulice")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                    visible={false}
                >
                </Column>
                <Column
                    key="mesto"
                    dataField="mesto"
                    caption={i18next.t("tab73s.mesto")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                >
                </Column>
                <Column
                    key="stat"
                    dataField="stat"
                    caption={i18next.t("tab73s.stat")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                >
                </Column>
                <Column
                    key="psc"
                    dataField="psc"
                    caption={i18next.t("tab73s.psc")}
                    dataType="string"
                    visible={false}
                >
                </Column>
                <Column
                    key="eposta"
                    dataField="eposta"
                    caption={i18next.t("tab73s.mail")}
                    dataType="string"
                >
                </Column>
                <Column
                    key="telefon"
                    dataField="telefon"
                    caption={i18next.t("tab73s.telefon")}
                    dataType="string"
                    visible={false}
                >
                </Column>
                {/*<Column*/}
                {/*    key="typ_odb"*/}
                {/*    dataField="typ_odb"*/}
                {/*    caption={i18next.t("tab73s.odberatel")}*/}
                {/*    dataType="boolean"*/}
                {/*    visible={false}*/}
                {/*>*/}
                {/*</Column>*/}
                <Column
                    key="typ_mvoo"
                    dataField="typ_mvoo"
                    caption={i18next.t("tab73s.odberatel")}
                    dataType="boolean"
                    visible={false}
                >
                </Column>
                <Column
                    key="typ_dop"
                    dataField="typ_dop"
                    caption={i18next.t("tab73s.dopravca")}
                    dataType="boolean"
                    visible={false}
                >
                </Column>
                <Column
                    type="buttons"
                    allowHiding={false}
                    showInColumnChooser={false}
                    visible={true}
                >
                    <Button name="edit" onClick={editRow}/>
                    <Button name="delete"/>
                </Column>
                <Toolbar>
                    <Item location="after">
                        <AddRowButton
                            icon="plus"
                            onClick={addRow}/>
                    </Item>
                    <Item name="columnChooserButton"/>
                    <Item name="exportButton"/>
                    <Item name="searchPanel"/>
                </Toolbar>
            </DataGrid>
            {visible ? (
                <Popup
                    title={isNewRecord ? i18next.t("tab73s.new_partner") : i18next.t("tab73s.edit_partner")}
                    hideOnOutsideClick={true}
                    visible={true}
                    height={"auto"}
                    onHiding={hidePopup}
                >
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={confirmBtnOptions}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={cancelBtnOptions}
                    />
                    <Form
                        formData={formData}
                        colCount={2}
                        validationGroup={validationGroupName}
                    >
                        <GroupItem caption={i18next.t("tab73s.zak_ud")}>
                            <SimpleItem dataField="x18"
                                        isRequired={true}
                                        editorOptions={numberBoxEditorOptions}
                                        editorType="dxNumberBox"
                            >
                                <Label text={i18next.t("tab73s.c_partnera")}/>
                                <RequiredRule />
                                <CustomRule
                                    message={i18next.t("tab73s.cis_unik")}
                                    validationCallback={validateUniqueS73}/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="s73"
                                editorOptions={numberBoxEditorOptions}
                                editorType="dxNumberBox"
                            >
                                <Label text={i18next.t("tab73s.ico")}/>
                            </SimpleItem>
                            <SimpleItem cssClass="button-right">
                                <DropDownButton
                                    splitButton={true}
                                    useSelectMode={false}
                                    type={'default'}
                                    stylingMode={'contained'}
                                    text={i18next.t("tab73s.nacitat_udaje_rpo")}
                                    items={buttonActions}
                                    keyExpr="id"
                                    displayExpr="text"
                                    onButtonClick={loadDataROP}
                                    onItemClick={loadDataItem}
                                />
                            </SimpleItem>
                            <SimpleItem dataField="nazev1" isRequired={true}>
                                <Label text={i18next.t("tab73s.nazov")}/>
                            </SimpleItem>
                            <SimpleItem dataField="nazev2">
                                <Label text={i18next.t("tab73s.nazov2")}/>
                            </SimpleItem>
                            <SimpleItem dataField="dic">
                                <Label text={i18next.t("tab73s.dic")}/>
                            </SimpleItem>
                            <SimpleItem dataField="sk_dic">
                                <Label text={i18next.t("tab73s.dic_sk")}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption={i18next.t("tab73s.adresa")}>
                            <SimpleItem dataField="ulice">
                                <Label text={i18next.t("tab73s.ulice")}/>
                            </SimpleItem>
                            <SimpleItem dataField="mesto">
                                <Label text={i18next.t("tab73s.mesto")}/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="psc"
                                editorOptions={pscEditorOptions}
                                editorType={"dxTextBox"}
                            >
                                <PatternRule
                                    message={"PSČ může obsahovat pouze čísla."}
                                    pattern={/^\d{0,5}$/}
                                />
                                <Label text={i18next.t("tab73s.psc")}/>
                            </SimpleItem>
                            <SimpleItem dataField="stat">
                                <Label text={i18next.t("tab73s.stat")}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption={i18next.t("tab73s.kontakty")}>
                            <SimpleItem dataField="eposta">
                                <Label text={i18next.t("tab73s.mail")}/>
                            </SimpleItem>
                            <SimpleItem dataField="telefon">
                                <Label text={i18next.t("tab73s.telefon")}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption={i18next.t("tab73s.pov_partnera")} colCount={4}>
                            {/*<SimpleItem dataField="typ_odb" editorType="dxCheckBox">*/}
                            {/*    <Label text={i18next.t("tab73s.odberatel")} location="left"/>*/}
                            {/*</SimpleItem>*/}
                            <SimpleItem dataField="typ_mvoo" editorType="dxCheckBox">
                                <Label text={i18next.t("tab73s.odberatel")} location="left"/>
                            </SimpleItem>
                            <SimpleItem dataField="typ_dop" editorType="dxCheckBox">
                                <Label text={i18next.t("tab73s.dopravca")} location="left"/>
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </Popup>
            ) : null}
        </ContentPage>
    );
}

export default Tab73sPage;
