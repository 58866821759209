import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './documents.scss';
import ContentPage from "../../components/content/content-page/ContentPage";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab36smlColumns} from "../../definitions/columns/tab36smlColumns";
import {LoadOptions} from "devextreme/data";
import HsftStore from "../../stores/baseStore";
import DataSource from "devextreme/data/data_source";
import {DateBoxTypes} from "devextreme-react/date-box";
import {ButtonTypes} from "devextreme-react/button";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {useTranslation} from "react-i18next";
import {
    ColumnChooser,
    ColumnChooserSelection,
    ColumnFixing, DataGrid, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, Paging,
    SearchPanel,
    Sorting,
    StateStoring, Summary
} from "devextreme-react/data-grid";
import {getCustomersViewColumns} from "../../definitions/columns/customersViewColumns";
import GridUtils from "../../utils/gridUtils";
import {getDocumentsViewColumns} from "../../definitions/columns/documentsViewColumns";
import {ColumnButtonClickEvent} from "devextreme/ui/data_grid";
import {getUsersColumns} from "../../definitions/columns/usersColumns";
import i18next from "i18next";
import Box, {Item} from "devextreme-react/box";
import Popup from "devextreme-react/popup";
import {getDocumentDetailsColumns} from "../../definitions/columns/documentDetailsColumns";

const getFormattedDate = (date: Date) => {
    return date.toISOString().split('T')[0];
};

const exportFormats = ['xlsx'];

const storeHSFTDocuments = new HsftStore('doklady');
const storeDocuments = storeHSFTDocuments.getStore();
const DocumentsDS = new DataSource({
    store: storeDocuments
});

const storeHSFTDocumentDetails = new HsftStore('doklady-detail');
const storeDocumentDetails = storeHSFTDocumentDetails.getStore();
const DocumentDetailsDS = new DataSource({
    store: storeDocumentDetails
});

function DocumentsPage() {
    const [visible, setVisible] = useState(false);

    const {t} = useTranslation();

    const toolbarTexts = {
        title: i18next.t("doklady.title"),
        do: t('until')
    };

    const gridRef = useRef<any>(null);
    const gridDetailRef = useRef<any>(null);
    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [detailId, setDetailId] = useState<number>(-1);
    const [detailNumber, setDetailNumber] = useState<number>(-1);

    const getDateParams = useCallback((loadOptions: LoadOptions) => {
        loadOptions.userData = {
            mesZ: dateFrom.getMonth() + 1,
            rokZ: dateFrom.getFullYear()
        };
    }, [dateFrom]);

    useEffect(() => {
        const handleLoading = (loadOptions: LoadOptions) => {
            getDateParams(loadOptions);
        };

        const handleDetailLoading = (loadOptions: LoadOptions) => {
            loadOptions.userData = {
                rowid: detailId,
            };
        };

        storeDocuments.on('loading', handleLoading);
        storeDocumentDetails.on('loading', handleDetailLoading);

        return () => {
            storeDocuments.off('loading', handleLoading);
            storeDocumentDetails.off('loading', handleDetailLoading);
        };
    }, [getDateParams, detailId]);

    const dateFromChanged = useCallback((e: DateBoxTypes.ValueChangedEvent) => {
        setDateFrom(e.value);
    }, []);

    const onDetailClick = useCallback(async (e: ColumnButtonClickEvent) => {

        if (e.row) {
            setDetailId(e.row.data.v01);
            setDetailNumber(e.row.data.b18);
            gridDetailRef.current.instance().refresh(true);
        }

        e.event!.preventDefault();
        setVisible(true);
    }, []);

    const getColumns = useCallback(() => {

        return getDocumentsViewColumns(onDetailClick);
    }, [onDetailClick]);

    const dateBoxFromOptions = useMemo(() => ({
        onValueChanged: dateFromChanged,
        value: dateFrom,
        type: "date",
        displayFormat: "M/y",
        calendarOptions:{
            maxZoomLevel: "year"
        }
    }), [dateFrom, dateFromChanged]);

    const setDate = useCallback((e: ButtonTypes.ClickEvent) => {
         gridRef.current.instance().refresh(true);
    }, [DocumentsDS, gridRef]);

    const searchButtonOptions = useMemo(() => ({
        icon: 'search',
        onClick: setDate
    }), [setDate]);

    const onExporting = useCallback((e: any) => {
        GridUtils.gridExport(e);
    }, []);

    const togglePopup = useCallback(() => {
        setVisible(false);
    }, []);

    return (
        <ContentPage title={""} description={""}>
            <Toolbar>
                <ToolbarItem
                    location="before"
                    text={toolbarTexts.title}
                >
                </ToolbarItem>
                <ToolbarItem
                    widget="dxDateBox"
                    location="before"
                    options={dateBoxFromOptions}
                >
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    location="before"
                    options={searchButtonOptions}
                >
                </ToolbarItem>
            </Toolbar>
            <DataGrid
                height={"100%"}
                ref={gridRef}
                id="grid-container"
                dataSource={DocumentsDS}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={true}
                remoteOperations={false}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                repaintChangesOnly={true}
                onExporting={onExporting}
            >
                <StateStoring enabled={true}
                              type="localStorage"
                              storageKey={"customersView"}/>
                <ColumnFixing enabled={true}/>
                <ColumnChooser enabled={true}
                               mode="select"
                               height={450}
                >
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}/>
                </ColumnChooser>
                <Sorting mode={"multiple"}/>
                <SearchPanel visible={true}/>
                <FilterRow visible={true}/>
                <HeaderFilter visible={true}/>
                <Export enabled={true} formats={exportFormats}/>
                <Paging
                    enabled={false}
                />
                {getColumns().map((column: any) => column)}
            </DataGrid>
            <Popup
                visible={visible}
                hideOnOutsideClick={true}
                onHiding={togglePopup}
                height={800}
                width={'95vw'}
                title={`Detail dokladu ${detailNumber}`}
            >
                <DataGrid
                    height={700}
                    ref={gridDetailRef}
                    id="grid-container"
                    dataSource={DocumentDetailsDS}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                    onExporting={onExporting}
                >
                    <StateStoring enabled={true}
                                  type="localStorage"
                                  storageKey={"customersView"}/>
                    <ColumnFixing enabled={true}/>
                    <ColumnChooser enabled={true}
                                   mode="select"
                                   height={450}
                    >
                        <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}/>
                    </ColumnChooser>
                    <Sorting mode={"multiple"}/>
                    <SearchPanel visible={true}/>
                    <FilterRow visible={true}/>
                    <HeaderFilter visible={true}/>
                    <Export enabled={true} formats={exportFormats}/>
                    <Paging
                        enabled={false}
                    />
                    {getDocumentDetailsColumns().map((column: any) => column)}
                </DataGrid>
            </Popup>
        </ContentPage>
    );
}
export default DocumentsPage;
