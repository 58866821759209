import React, {useCallback, useState} from 'react';
import './TAB52.scss';
import DataSource from 'devextreme/data/data_source';
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import HsftStore from "../../stores/baseStore";
import {getTab52sColumns} from "../../definitions/columns/tab52sColumns";
import {getTab31sColumns} from "../../definitions/columns/tab31sColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import i18next from 'i18next';

function uniqueValidation(e: any) {
    if (e.isValid) {
        let id = 0;
        if ((e.oldData) && ('rowid' in e.oldData)) {
            id = e.oldData.rowid;
        }
        let updatedData: any = {};
        if (e.oldData) {
            updatedData = {...e.oldData, ...e.newData}
        } else {
            updatedData = e.newData;
        }

        for (const item of tab52DataSource.items()) {
            if ((item.rowid !== id) && (item.vyk === updatedData.vyk) && (item.podvyk === updatedData.podvyk)) {
                e.isValid = false;
                e.errorText = i18next.t("tab52.error.unik_podvykon");
            }
        }
    }
}

const hsftStore = new HsftStore('TAB52');

const tab52DataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab13sPage() {
    return (
        <ContentPage title={i18next.t("tab52.title")} description={i18next.t("tab52.desc")} >
            <HsftDataGrid
                id={"TAB52"}
                editable={true}
                columns={getTab52sColumns}
                dataSource={tab52DataSource}
                onRowValidating={uniqueValidation}
            />
        </ContentPage>
    );
}

export default Tab13sPage;
