import i18next from "i18next";

export function navigation()
{ return [
  {
    text: i18next.t("app_nav.uvod"),
    path: '/home',
    icon: 'home'
  },
  {
    text: (i18next.t("app_nav.nast")),
    path: '/preferences',
    icon: 'preferences'
  },
  {
    text: i18next.t("app_nav.cis"),
    icon: 'ordersbox',
    expanded: false,
    items: [
      {
        text: i18next.t("app_nav.popis"),
        path: '/taborg',
      },
      {
        text: i18next.t("app_nav.strediska"),
        path: '/tab12s'
      },
      {
        text: i18next.t("app_nav.useky"),
        path: '/tab13s'
      },
      {
        text: i18next.t("app_nav.sklady"),
        path: '/tab29z',
      },
      {
        text: i18next.t("app_nav.prac"),
        path: '/tab80v',
      },
      {
        text: i18next.t("app_nav.partneri"),
        path: '/tab73s',
      },
      {
        text: i18next.t("app_nav.smlouvy"),
        path: '/tab36-sml'
      },
      {
        text: i18next.t("app_nav.cert"),
        path: '/tabcer',
      },
      {
        text: i18next.t("app_nav.vyk"),
        path: '/tab31s'
      },
      {
        text: i18next.t("app_nav.podvyk"),
        path: '/tab52',
      },
      {
        text: i18next.t("app_nav.prost"),
        path: '/tab33s',
      },
      {
        text: i18next.t("app_nav.voz"),
        path: '/tab79rz',
      },
      {
        text: i18next.t("app_nav.kub_met"),
        path: '/tab10',
      },
      {
        text: i18next.t("app_nav.drev"),
        path: '/tab57',
      },
      {
        text: i18next.t("app_nav.sort"),
        path: '/tab71t',
      },
      {
        text: i18next.t("app_nav.akost_hr"),
        path: '/tabtls',
      },
      {
        text: i18next.t("app_nav.hr_st"),
        path: '/tabts',
      }
    ]
  },
  {
    text: i18next.t("app_nav.users"),
    path: '/users',
    icon: 'group'
  }
,
  {
    text: i18next.t("app_nav.doklady"),
    path: '/documents',
    icon: 'folder'
  }
  ]
}

