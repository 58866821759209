import React, {useRef, useMemo, useCallback, useState} from 'react';
import './TABCER.scss';
import i18next from 'i18next';
import i18n from './../../i18n';
import {
    Column,
    ColumnChooser,
    ColumnFixing,
    DataGrid, Editing,
    FilterRow,
    HeaderFilter, Paging, RequiredRule,
    SearchPanel,
    Sorting,
    Button,
    Toolbar,
    Item,
    Export, Lookup,
    ColumnChooserSelection
} from "devextreme-react/data-grid";
import {Lookup as FormLookup, DropDownOptions} from 'devextreme-react/lookup';
import DropDownButton, {} from 'devextreme-react/drop-down-button';
import Popup, {ToolbarItem} from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";
import CustomStore from "devextreme/data/custom_store";
import {tab73sAPI} from "../../api/tab73sAPI";
import {skRPOAPI} from "../../api/skRPO";
import {czARESAPI} from "../../api/czARES";
import DataSource from "devextreme/data/data_source";
import Form, {GroupItem, Label, SimpleItem, CustomRule} from "devextreme-react/form";
import AddRowButton from 'devextreme-react/button';
import validationEngine from 'devextreme/ui/validation_engine';
import GridUtils from "../../utils/gridUtils";
import HsftStore from "../../stores/baseStore";
import {FileUploader, SelectBox} from "devextreme-react";
import ContentPage from "../../components/content/content-page/ContentPage";

function sortStringsConsideringCulture(value1: string, value2: string) {
    // Handling null values
    if (!value1 && value2) return -1;
    if (!value1 && !value2) return 0;
    if (value1 && !value2) return 1;
    // Determines whether two strings are equivalent in the current locale
    return value1.localeCompare(value2);
}

function notifySave() {
    notify(
        {
            message: i18n.t("tabcer.hod_ulozene"),
            width: 230,
            height: 75,
            // shading: true,
            animation: {
                show: {
                    type: 'fade', duration: 500, from: 0, to: 1,
                },
                hide: {type: 'fade', duration: 750, from: 1, to: 0},
            },
            position: {
                at: "bottom center",
                my: "bottom center",
                of: "#main-content"
            }
        },
        "success",
        2000);
}


const hsftStore = new HsftStore('TABCER');

const tabCERDataSource = new DataSource({
    store: hsftStore.getStore()
});

const exportFormats = ['xlsx'];

const typLookupDataSourceConfig: any = {
    store: {
        type: 'array',
        data: [
            {id: 1, name: 'PEFC'},
            {id: 2, name: 'FSC'},
            {id: 3, name: 'C-o-C'}
        ],
        key: 'id'
    },
    paginate: false
};

const typSelectBoxOptions = {
    dataSource: typLookupDataSourceConfig,
    valueExpr: "id",
    displayExpr: "name"
};


const validationGroupName = "gridForm";

function TabCERPage() {
    const gridRef = useRef<any>(null);
    const [formData, setFormData] = useState<any>({});
    const [{isNewRecord, visible}, setPopupState] = useState<any>({});
    const [fileData, setFileData] = useState<string>('');
    const [base64Logo, setBase64Logo] = useState<string | null>(null);

    const showPopup = useCallback((isNewRecord: any, initFormData: any) => {
        setPopupState({isNewRecord, visible: true});
        setFormData(initFormData);
        setBase64Logo(initFormData['logo_mob']);
    }, []);

    const hidePopup = useCallback(() => {
        setPopupState({visible: false});
        setFormData({});
    }, []);

    const fileChanged = useCallback((e: any) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            if (reader.result && typeof reader.result === 'string') {
                const cleanBase64String = reader!.result.split(',')[1]
                setFileData(cleanBase64String);
                setBase64Logo(cleanBase64String);
            }
            // this.setState({ fileData: reader.result });
        };

        reader.readAsDataURL(e.value![0]);

    }, []);

    const confirmChanges = useCallback(() => {
        const result = validationEngine.validateGroup(validationGroupName);

        if (!result.isValid)
            return;

        let values: any = {...formData};
        delete values['rowid'];
        delete values['logo_mob'];

        if (fileData !== '') {
            values['logo_mob'] = fileData;
        }

        if (isNewRecord) {
            tabCERDataSource.store().insert(values);
        } else {
            tabCERDataSource.store().update(formData["rowid"], values);
        }

        gridRef.current.instance().refresh(true);
        hidePopup();

    }, [isNewRecord, formData, hidePopup, fileData]);

    const confirmBtnOptions = useMemo(() => {
        return {
            text: i18next.t("tabcer.uloz"),
            type: "default",
            stylingMode: "contained",
            onClick: confirmChanges
        }
    }, [confirmChanges]);

    const cancelBtnOptions = useMemo(() => {
        return {
            text: i18next.t("tabcer.zrusit"),
            stylingMode: "contained",
            type: "normal",
            onClick: hidePopup
        }
    }, [hidePopup]);

    const editRow = useCallback((e: any) => {
        showPopup(false, {...e.row.data});
    }, [showPopup]);

    const addRow = useCallback(() => {
        showPopup(true, {
            kod: null,
            kpl: null,
            typ: 1
        });
    }, [showPopup]);

    const onExporting = useCallback((e: any) => {
        GridUtils.gridExport(e);
    }, []);

    const validateUniqueKod = useCallback((options: any) => {
        let result = true;
        let id = 0;

        if ((formData) && ('rowid' in formData)) {
            id = formData.rowid;
        }

        for (const item of tabCERDataSource.items()) {
            if ((item.rowid !== id) && (item.kod === options.value)) {
                result = false;
            }
        }
        return result;

    }, [formData]);

    return (
        <ContentPage title={i18next.t("tabcer.title")} description={i18next.t("tabcer.desc")}>
            <DataGrid
                ref={gridRef}
                id="grid-container"
                dataSource={tabCERDataSource}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={true}
                remoteOperations={false}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                repaintChangesOnly={true}
                onExporting={onExporting}
            >
                <Export enabled={true} formats={exportFormats}/>
                <ColumnFixing enabled={true}/>
                <ColumnChooser enabled={true}
                               mode="select"
                               height={450}
                >
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}/>
                </ColumnChooser>
                <Sorting mode={"multiple"}/>
                <SearchPanel visible={true}/>
                <FilterRow visible={true}/>
                <HeaderFilter visible={true}/>
                <Editing
                    mode="popup"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                >
                </Editing>
                <Paging
                    enabled={false}
                />
                <Column
                    dataField="kod"
                    caption={i18next.t("tabcer.kod")}
                    dataType="number"
                    width={250}
                    defaultSortOrder="asc"
                >
                    <RequiredRule/>
                </Column>
                <Column
                    dataField="kpl"
                    caption={i18next.t("tabcer.kod_p")}
                    dataType="number"
                    width={250}
                >
                    <RequiredRule/>
                </Column>
                <Column
                    dataField="typ"
                    caption={i18next.t("tabcer.typ")}
                    dataType="number"
                    width={250}
                >
                    <RequiredRule/>
                    <Lookup
                        dataSource={typLookupDataSourceConfig}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>
                <Column
                    dataField="nazev"
                    caption={i18next.t("tabcer.nazov")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                >
                </Column>
                <Column
                    dataField="osv"
                    caption={i18next.t("tabcer.osv")}
                    dataType="number"
                >
                </Column>
                <Column
                    dataField="plod"
                    caption={i18next.t("tabcer.plod")}
                    dataType="date"
                >
                </Column>
                <Column
                    dataField="pldo"
                    caption={i18next.t("tabcer.pldo")}
                    dataType="date"
                >
                </Column>
                <Column
                    dataField="popis"
                    caption={i18next.t("tabcer.popis")}
                    dataType="string"
                    sortingMethod={sortStringsConsideringCulture}
                    visible={false}
                >
                </Column>
                <Column
                    type="buttons"
                    allowHiding={false}
                    showInColumnChooser={false}
                    visible={true}
                >
                    <Button name="edit" onClick={editRow}/>
                    <Button name="delete"/>
                </Column>
                <Toolbar>
                    <Item location="after">
                        <AddRowButton
                            icon="plus"
                            onClick={addRow}/>
                    </Item>
                    <Item name="columnChooserButton"/>
                    <Item name="exportButton"/>
                    <Item name="searchPanel"/>
                </Toolbar>
            </DataGrid>
            {visible ? (
                <Popup
                    title={isNewRecord ? i18next.t("tabcer.new_cert") : i18next.t("tabcer.edit_cert")}
                    hideOnOutsideClick={true}
                    visible={true}
                    height={"auto"}
                    onHiding={hidePopup}
                >
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={confirmBtnOptions}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={cancelBtnOptions}
                    />
                    <Form
                        formData={formData}
                        validationGroup={validationGroupName}
                    >
                        <GroupItem caption={i18next.t("tabcer.zak_ud")} colCount={2}>
                            <SimpleItem
                                dataField="kod"
                                editorType="dxNumberBox"
                            >
                                <Label text={i18next.t("tabcer.kod")}/>
                                <CustomRule message={i18next.t("tabcer.kod_unik")}
                                            validationCallback={validateUniqueKod}/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="kpl"
                                editorType="dxNumberBox"
                            >
                                <Label text={i18next.t("tabcer.kod_p")}/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="typ"
                                editorType="dxSelectBox"
                                editorOptions={typSelectBoxOptions}
                            >
                                <Label text={i18next.t("tabcer.typ")}/>
                            </SimpleItem>
                            <SimpleItem dataField="nazev" isRequired={true}>
                                <Label text={i18next.t("tabcer.nazov")}/>
                            </SimpleItem>
                            <SimpleItem dataField="osv">
                                <Label text={i18next.t("tabcer.osv")}/>
                            </SimpleItem>
                            <SimpleItem dataField="popis" editorType="dxTextArea">
                                <Label text="Popis"/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption="Platnost" colCount={2}>
                            <SimpleItem dataField="plod" editorType="dxDateBox">
                                <Label text={i18next.t("tabcer.plod")}/>
                            </SimpleItem>
                            <SimpleItem dataField="pldo" editorType="dxDateBox">
                                <Label text={i18next.t("tabcer.pldo")}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption={i18next.t("tabcer.logo_cer")} colCount={2}>
                            <SimpleItem dataField="logo">
                                <Label visible={false}/>
                                <FileUploader
                                    selectButtonText={i18next.t("tabcer.vyb_logo")}
                                    allowCanceling={true}
                                    labelText={i18next.t("tabcer.prot_sem")}
                                    accept="image/*"
                                    name="Logo"
                                    height={100}
                                    uploadMode="useForm"
                                    onValueChanged={fileChanged}
                                />
                            </SimpleItem>
                            <SimpleItem>
                                    <img
                                        hidden={!base64Logo}
                                        className={"logoImg"}
                                        src={`data:image/png;base64,${base64Logo}`}
                                    />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </Popup>
            ) : null}
        </ContentPage>
    );
}

export default TabCERPage;
