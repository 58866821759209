import React, {useCallback, useState} from 'react';
import DataGrid, {
    Column,
    DataGridTypes,
    Paging,
    Scrolling,
    Selection,
} from 'devextreme-react/data-grid';
import DropDownBox, {DropDownBoxTypes} from 'devextreme-react/drop-down-box';
import ColumnUtils from "../../utils/columnUtils";
import i18next from 'i18next';

const dropDownOptions = {width: 500};
const ownerLabel = {'aria-label': 'Owner'};

const UtvarDropDownBoxComponent = (props: any) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([props.data.value]);
    const [isDropDownOpened, setDropDownOpened] = useState(false);

    const boxOptionChanged = useCallback((e: DropDownBoxTypes.OptionChangedEvent) => {
        if (e.name === 'opened') {
            setDropDownOpened(e.value);
        }
    }, []);

    const contentRender = useCallback(() => {
        const onSelectionChanged = (args: DataGridTypes.SelectionChangedEvent) => {
            setSelectedRowKeys(args.selectedRowKeys);

            if (args.selectedRowKeys.length > 0) {
                setDropDownOpened(false);
            }
            // props.data.setValue(args.selectedRowKeys[0]);
            if (args.selectedRowsData[0]) {
                props.data.setValue(args.selectedRowsData[0].s12);
            }
        };

        return (
            <DataGrid
                dataSource={props.data.column.lookup.dataSource}
                remoteOperations={false}
                height={250}
                selectedRowKeys={selectedRowKeys}
                hoverStateEnabled={true}
                onSelectionChanged={onSelectionChanged}
                focusedRowEnabled={true}
                defaultFocusedRowKey={selectedRowKeys[0]}
            >
                <Column
                    dataField="s12"
                    caption={i18next.t("utvar_td.kod_str")}
                    dataType="number"
                    defaultSortOrder="asc"
                />
                <Column
                    dataField="nazev"
                    caption={i18next.t("utvar_td.nazov_str")}
                    dataType="string"
                    sortingMethod={ColumnUtils.sortStringsConsideringCulture}
                />
                <Paging enabled={false}/>
                <Scrolling mode="virtual"/>
                <Selection mode="single"/>
            </DataGrid>
        );
    }, [props.data, selectedRowKeys]);
    return (
        <DropDownBox
            onOptionChanged={boxOptionChanged}
            opened={isDropDownOpened}
            dropDownOptions={dropDownOptions}
            dataSource={props.data.column.lookup.dataSource}
            value={selectedRowKeys[0]}
            displayExpr="s12"
            valueExpr="s12"
            inputAttr={ownerLabel}
            contentRender={contentRender}>
        </DropDownBox>
    );
};

export default UtvarDropDownBoxComponent;
