import React from 'react';
import './TAB33S.scss';
import DataSource from 'devextreme/data/data_source';
import HsftStore from "../../stores/baseStore";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab33sColumns} from "../../definitions/columns/tab33sColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import i18next from 'i18next';

const hsftStore = new HsftStore('TAB33S');

const tab33SDataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab33sPage() {

    return (
        <ContentPage title={i18next.t("tab33s.title")} description={i18next.t("tab33s.desc")} >
            <HsftDataGrid
                id={"TAB33S"}
                editable={true}
                columns={getTab33sColumns}
                dataSource={tab33SDataSource}
            />
        </ContentPage>
    );
}
export default Tab33sPage;
