import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, {useEffect} from 'react';
import {HashRouter as Router} from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import {NavigationProvider} from './contexts/navigation';
import {AuthProvider, useAuth} from './contexts/auth';
import {useScreenSizeClass} from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import csMessages from "devextreme/localization/messages/cs.json";
import skMessages from "./sk.json";
import {locale, loadMessages} from "devextreme/localization";
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {useTranslation, I18nextProvider} from 'react-i18next';
import i18n from './i18n'; // Importuj konfigurační soubor i18next

function App() {
    const {user, loading} = useAuth();


    loadMessages(csMessages);
    loadMessages(skMessages);
    //locale(navigator.language);
    locale('sk');

    const { i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage('sk');
    }, []);


    if (loading) {
        return <LoadPanel visible={true}/>;
    }

    if (user) {
        return <Content/>;
    }

    return <UnauthenticatedContent/>;
}

const queryClient = new QueryClient();

export default function Root() {
    const screenSizeClass = useScreenSizeClass();

    return (
        <Router>
            <I18nextProvider i18n={i18n}>
                <AuthProvider>
                    <NavigationProvider>
                        <QueryClientProvider client={queryClient}>
                            <div className={`app ${screenSizeClass}`}>
                                <App/>
                            </div>
                        </QueryClientProvider>
                    </NavigationProvider>
                </AuthProvider>
            </I18nextProvider>
        </Router>
    );
}
