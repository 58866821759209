import {Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";
import i18next from 'i18next';

const kodEditorOptions = {
    min: 1,
    max: 9999999,
    inputAttr: {
        maxLength: 7
    }
};

function getTab80vColumns(): Array<any> {
    return [
        <Column
            key="cis"
            dataField="cis"
            caption={i18next.t("tab80v_columns.cis")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
            editorOptions= {kodEditorOptions}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="nazev"
            dataField="nazev"
            caption={i18next.t("tab80v_columns.nazev")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="man"
            dataField="man"
            caption={i18next.t("tab80v_columns.man")}
            dataType="boolean"
            width={165}
        >
        </Column>
    ];
}

export {getTab80vColumns};
