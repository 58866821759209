import React, {Fragment, useCallback, useEffect, useState} from 'react';
import './TABORG.scss';
import {FileUploader} from "devextreme-react";
import {ORG} from "../../models/ORG";
// import {tabORGAPI} from "../../api/tabORGAPI";
import i18next from 'i18next';
import i18n from './../../i18n';
import notify from "devextreme/ui/notify";
import {LoadPanel} from "devextreme-react/load-panel";
import {ButtonItem, CustomRule, Form, GroupItem, Label, SimpleItem} from "devextreme-react/form";
import Switch from "devextreme-react/switch";
import {ButtonStyle, ButtonType} from "devextreme/common";
import DropDownButton from "devextreme-react/drop-down-button";
import {createCatalogAPI} from "../../api/catalogAPI";
import ContentPage from "../../components/content/content-page/ContentPage";

const loadPanelPosition = {of: '#main-content'};

const submitButtonOptions: { text: string, useSubmitBehavior: boolean, type: ButtonType, stylingMode: ButtonStyle } = {
    text: i18n.t("taborg.uloz"),
    useSubmitBehavior: true,
    type: "default",
    stylingMode: "contained"
};

const TabORGConfig: ORG = {
    rowid: '',
    cior: '',
    cipo: '',
    eposta: '',
    fax: '',
    ic: '',
    mesto: '',
    nazev1: '',
    nazev2: '',
    psc: '',
    stat: '',
    telefon: '',
    ulice: '',
    logo_mob: ''
}

function TabORGForm() {
    const [config, setConfig] = useState<ORG>(TabORGConfig);
    // const [mujConfig, setMujConfig] = useState<Config>(baseConfig);
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [base64Logo, setBase64Logo] = useState<string | null>(null);

    const tabORGAPI = createCatalogAPI('TABORG');

    useEffect(() => {
        async function loadConfig() {
            setLoading(true);
            try {
                const response = await tabORGAPI.load();
                if (response && response.data) {
                    const data = response.data;
                    setError('');
                    if (data.length === 1) {
                        let config = data[0];

                        setBase64Logo(config['logo_mob']);

                        delete config['logo'];
                        delete config['logo_mob'];

                        setConfig(config);
                    }
                }
            } catch
                (e) {
                if (e instanceof Error) {
                    setError(e.message);
                }
            } finally {
                setLoading(false);
            }
        }

        loadConfig();
    }, []);

    const handleSubmit = useCallback((e: any) => {
        async function saveConfig() {
            try {
                let values: any = {...config};

                delete values['rowid'];

                if (fileData !== '') {
                    values['logo_mob'] = fileData;
                }

                await tabORGAPI.update(config.rowid, values);
                setError('');
                // notify("Konfigurace byla uložena", "success", 2000);
                notify(
                    {
                        message: i18n.t("taborg.hod_ulozene"),
                        width: 230,
                        height: 75,
                        // shading: true,
                        animation: {
                            show: {
                                type: 'fade', duration: 500, from: 0, to: 1,
                            },
                            hide: {type: 'fade', duration: 750, from: 1, to: 0},
                        },
                        position: {
                            at: "bottom center",
                            my: "bottom center",
                            of: "#main-content"
                        }
                    },
                    "success",
                    2000);
            } catch (e) {
                if (e instanceof Error) {
                    setError(e.message);
                }
            } finally {

            }
        }

        saveConfig();
        e.preventDefault();
    }, [config, fileData]);

    const fileChanged = useCallback((e: any) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            if (reader.result && typeof reader.result === 'string') {
                const cleanBase64String = reader!.result.split(',')[1]
                setFileData(cleanBase64String);
                setBase64Logo(cleanBase64String);
            }
        };

        reader.readAsDataURL(e.value![0]);

    }, []);

    return (
        <Fragment>
            <form action="/taborg" onSubmit={handleSubmit}>
                <LoadPanel
                    position={loadPanelPosition}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={loading}
                    shading={true}
                    showIndicator={true}
                    showPane={true}
                    hideOnOutsideClick={false}

                />
                <Form
                    formData={config}
                    labelLocation={"left"}
                    colCount={2}
                    id="form"
                >
                    <GroupItem colCount={1}>
                        <GroupItem caption={i18next.t("taborg.zak_udaje")} colSpan={1}>
                            <SimpleItem
                                dataField="ic"
                                editorType="dxNumberBox"
                            >
                                <Label text={i18next.t("taborg.ico")}/>
                            </SimpleItem>
                            <SimpleItem dataField="nazev1" isRequired={true}>
                                <Label text={i18next.t("taborg.nazov")}/>
                            </SimpleItem>
                            <SimpleItem dataField="nazev2">
                                <Label text={i18next.t("taborg.nazov2")}/>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption={i18next.t("taborg.kontakty")}>
                            <SimpleItem dataField="eposta">
                                <Label text={i18next.t("taborg.mail")}/>
                            </SimpleItem>
                            <SimpleItem dataField="telefon">
                                <Label text={i18next.t("taborg.telefon")}/>
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                    <GroupItem caption={i18next.t("taborg.adresa")}>
                        <SimpleItem dataField="ulice">
                            <Label text={i18next.t("taborg.ulica")}/>
                        </SimpleItem>
                        <SimpleItem dataField="cipo">
                            <Label text={i18next.t("taborg.cipo")}/>
                        </SimpleItem>
                        <SimpleItem dataField="cior">
                            <Label text={i18next.t("taborg.cior")}/>
                        </SimpleItem>
                        <SimpleItem dataField="mesto" isRequired={true}>
                            <Label text={i18next.t("taborg.mesto")}/>
                        </SimpleItem>
                        <SimpleItem dataField="psc" isRequired={true}>
                            <Label text={i18next.t("taborg.psc")}/>
                        </SimpleItem>
                        <SimpleItem dataField="stat" isRequired={true}>
                            <Label text={i18next.t("taborg.stat")}/>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={i18next.t("taborg.logo_org")}>
                        <SimpleItem dataField="logo">
                            <Label visible={false}/>
                            <FileUploader
                                selectButtonText={i18next.t("taborg.vyb_logo")}
                                allowCanceling={true}
                                labelText={i18next.t("taborg.prot_sem")}
                                accept="image/*"
                                name="Logo"
                                height={100}
                                uploadMode="useForm"
                                onValueChanged={fileChanged}
                            />
                        </SimpleItem>
                    </GroupItem>
                    <SimpleItem>
                            <img
                                hidden={!base64Logo}
                                className={"logoImg"}
                                src={`data:image/png;base64,${base64Logo}`}
                            />
                    </SimpleItem>
                    <GroupItem cssClass="buttons-group">
                        <ButtonItem buttonOptions={submitButtonOptions}/>
                    </GroupItem>
                </Form>
            </form>
        </Fragment>);
}

function TabORGPage() {


    return (
        <ContentPage title={i18next.t("taborg.title")} description={i18next.t("taborg.desc")}>
            <TabORGForm/>
        </ContentPage>
    );
}

export default TabORGPage;
