import React, {useCallback, useState} from 'react';
import './users.scss';
import ContentPage from "../../components/content/content-page/ContentPage";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import HsftStore from "../../stores/baseStore";
import DataSource from "devextreme/data/data_source";
import {getUsersColumns} from "../../definitions/columns/usersColumns";
import Box, {Item} from "devextreme-react/box";
import Popup from "devextreme-react/popup";
import { ColumnButtonClickEvent } from "devextreme/ui/data_grid"
import {createCatalogAPI} from "../../api/catalogAPI";
import { LoadOptions } from "devextreme/data/index";
import i18next from 'i18next';
import i18n from '../../i18n';

const hsftStore = new HsftStore('uzivatele');

const usersDataSource = new DataSource({
    store: hsftStore.getStore()
});

const qrAPI = createCatalogAPI("qr");

function UsersPage() {

    const [visible, setVisible] = useState(false);
    const [base64Image, setBase64Image] = useState<string | null>(null);

    const onQRIconClick = useCallback(async (e: ColumnButtonClickEvent) => {

        if (e.row) {
            const loadOptions: LoadOptions = {
                userData: {
                    login: e.row.data.login,
                    cisloProfilu: e.row.data.cisloProfilu
                }
            }

            const data = await qrAPI.load(loadOptions);

            if (data && data.data && data.data.length === 1) {
                setBase64Image(data.data[0].qr);
            }
        }

        e.event!.preventDefault();
        setVisible(true);
    }, []);

    const getColumns = useCallback(() => {

        return getUsersColumns(onQRIconClick);
    }, [onQRIconClick]);

    const togglePopup = useCallback(() => {

        setVisible(false);
    }, []);

   // const columns =  getUsersColumns(onQRIconClick);

    return (
        <ContentPage title={i18next.t("users.title")} description={i18next.t("users.desc")}>
            <Box className={"linkBox"} direction="row" width="100%" height={225}>
                <Item ratio={1}/>
                <Item ratio={2}>
                    <Box className={"linkInnerBox"} direction={"row"} width={"100%"} height={225}>
                        <Item ratio={1}>
                            <p>Aplikáciu Drevo si môžete do svojho telefónu alebo tabletu stiahnuť v Google Play</p>
                        </Item>
                        <Item ratio={1}>
                            <a href="https://play.google.com/store/apps/details?id=cz.hasoft.drevo&hl=sk"
                               target="_blank">
                                <img className={"linkImg"}
                                    src="/google-play-badge.png"
                                    alt="Google Play Badge"
                                />
                            </a>
                        </Item>
                    </Box>
                </Item>
                <Item ratio={1}/>
                <Item ratio={2}>
                    <Box className={"linkInnerBox"} direction={"row"} width={"100%"} height={225}>
                        <Item ratio={1}>
                            <p>{i18next.t("users.qr_download")}</p>
                        </Item>
                        <Item ratio={1}>
                            <img
                                className={"linkImg"}
                                src={"/DREVO-GP.png"}
                            />
                        </Item>
                    </Box>
                </Item>
                <Item ratio={1}/>
            </Box>
            <HsftDataGrid
                id={"users"}
                editable={false}
                columns={getColumns}
                dataSource={usersDataSource}
            />
            <Popup
                visible={visible}
                hideOnOutsideClick={true}
                onHiding={togglePopup}
                height={450}
                title={i18next.t("users.qr_prihl")}
            >
                <Box className={"linkBox"} direction="row" width="100%" height={300}>
                    <Item ratio={1}/>
                    <Item ratio={2}>
                        <Box className={"linkInnerBox"} direction={"row"} width={"100%"} height={300}>
                            <Item ratio={1}>
                                <p>{i18next.t("users.qr_login")}</p>
                            </Item>
                            <Item ratio={1}>
                                {base64Image && (
                                    <img
                                        className={"loginImg"}
                                        src={`data:image/png;base64,${base64Image}`}
                                        alt={i18next.t("users.qr_prihl")}
                                    />
                                )}
                            </Item>
                        </Box>
                    </Item>
                    <Item ratio={1}/>
                </Box>
            </Popup>
        </ContentPage>
    );
}

export default UsersPage;
