import React from 'react';
import './TAB36SML.scss';

import ContentPage from "../../components/content/content-page/ContentPage";
import i18next from 'i18next';
import HsftStore from "../../stores/baseStore";
import DataSource from "devextreme/data/data_source";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab36smlColumns} from "../../definitions/columns/tab36smlColumns";

const hsftStore = new HsftStore('TAB36SML');

const tab36SMLDataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab36smlPage() {

    return (
        <ContentPage title={i18next.t("tab36sml.title")} description={i18next.t("tab36sml.desc")} >
            <HsftDataGrid
                id={"TAB36SML"}
                editable={true}
                columns={getTab36smlColumns}
                dataSource={tab36SMLDataSource}
            />
        </ContentPage>
    );
}
export default Tab36smlPage;
