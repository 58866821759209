import React, { ReactNode } from 'react';

interface ContentPageProps {
    title: string;
    description: string;
    children: ReactNode;
}

export default function ContentPage({ title, description, children }: ContentPageProps) {
    return (
        <React.Fragment>
            <div className="page-container">
                <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'}>
                        {title && (<h2 className={'page-heading'}>{title}</h2>)}
                        {description && (<p className={'page-description'}>{description}</p>)}
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
