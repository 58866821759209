import React, {useCallback, useMemo} from 'react';

import './HsftDataGrid.scss';
import {
    ColumnChooser, ColumnChooserSelection,
    ColumnFixing, DataGrid,
    Editing, Export,
    FilterRow,
    HeaderFilter, Paging,
    SearchPanel,
    Sorting,
    StateStoring
} from "devextreme-react/data-grid";
import type {HsftDataGridProps} from '../../../types';
import GridUtils from "../../../utils/gridUtils";


const exportFormats = ['xlsx'];
export default function HsftDataGrid(
    {
        id,
        editable,
        dataSource,
        columns,
        onRowValidating,
        modifiable = true
    }: HsftDataGridProps) {

    const storageId = useMemo(() => {
        return `datagrid_${id}`;
    }, [id]);

    const onExporting = useCallback((e: any) => {
        GridUtils.gridExport(e);
    }, []);

    const onRowUpdating = useCallback((e: any) => {
        if (e.oldData.x7x_cl) {
            e.newData.x7x_cl = e.oldData.x7x_cl;
        }
    }, []);

    return (
        <DataGrid
            id="grid-container"
            dataSource={dataSource}
            onRowValidating={onRowValidating}
            onRowUpdating={onRowUpdating}
            height={"90%"}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            rowAlternationEnabled={true}
            remoteOperations={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            onExporting={onExporting}
        >
            <StateStoring enabled={true}
                          type="localStorage"
                          storageKey={storageId}/>
            <ColumnFixing enabled={true}/>
            <ColumnChooser enabled={true}
                           mode="select"
                           height={450}
            >
                <ColumnChooserSelection
                    allowSelectAll={true}
                    selectByClick={true}/>
            </ColumnChooser>
            <Sorting mode={"multiple"}/>
            <SearchPanel visible={true}/>
            <FilterRow visible={true}/>
            <HeaderFilter visible={true}/>
            <Export enabled={true} formats={exportFormats}/>
            {editable ? (
                <Editing
                    mode="row"
                    allowAdding={modifiable}
                    allowDeleting={modifiable}
                    allowUpdating={true}
                >
                </Editing>) : null}
            <Paging
                enabled={false}
            />
            {columns().map((column: any) => column)}
        </DataGrid>
    )
}
