import {Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";
import i18next from 'i18next';

const kodEditorOptions = {
    min: 1,
    max: 999,
    format: '###',
    inputAttr: {
        maxLength: 3
    }
};

const jlLookupDataSourceConfig: any = {
    store: {
        type: 'array',
        data: [
            {id: 1, name: i18next.t("tab57_columns.jehl")},
            {id: 2, name: i18next.t("tab57_columns.list")}
        ],
        key: 'id'
    },
    paginate: false
}

function getTab57Columns(): Array<any> {
    return [
        <Column
            key="kod"
            dataField="kod"
            caption={i18next.t("tab57_columns.kod")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
            editorOptions={kodEditorOptions}
        >
            <RequiredRule/>
            <PatternRule
                message={i18next.t("tab57_columns.message_drev")}
                pattern={/^\d{1,2}$/}
            />
        </Column>,
        <Column
            key="zkr"
            dataField="zkr"
            caption={i18next.t("tab57_columns.zkr")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="nazev"
            dataField="nazev"
            caption={i18next.t("tab57_columns.nazev")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="zkrn"
            dataField="zkrn"
            caption={i18next.t("tab57_columns.zkrn")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="jl"
            dataField="jl"
            caption={i18next.t("tab57_columns.jl")}
        >
            <Lookup
                dataSource={jlLookupDataSourceConfig}
                valueExpr="id"
                displayExpr="name"
            />
            <RequiredRule/>
        </Column>,
        // <Column
        //     key="sdt"
        //     dataField="sdt"
        //     caption="Skupina"
        // >
        //     <RequiredRule />
        // </Column>,
    ];
}

export {getTab57Columns};
