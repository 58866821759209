import React from 'react';
import './TAB10.scss';
import DataSource from 'devextreme/data/data_source';
import HsftStore from "../../stores/baseStore";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab10Columns} from "../../definitions/columns/tab10Columns";
import ContentPage from "../../components/content/content-page/ContentPage";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

function uniqueValidation(e: any) {
    if (e.isValid) {
        let id = '';
        if ((e.oldData) && ('rowid' in e.oldData)) {
            id = e.oldData.rowid;
        }
        let updatedData: any = {};
        if (e.oldData) {
            updatedData = {...e.oldData, ...e.newData}
        } else {
            updatedData = e.newData;
        }

        if (e.isValid) {
            if (updatedData.prum_od > updatedData.prum_do) {
                e.isValid = false;
                e.errorText = i18next.t("error.prumer_od");
            }
        }
        if (e.isValid) {
            if (updatedData.delka_od > updatedData.delka_do) {
                e.isValid = false;
                e.errorText = i18next.t("error.delka_od");
            }
        }
    }
}

const hsftStore = new HsftStore('TAB10');

const tab10DataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab10Page() {

    return (
        <ContentPage title={i18next.t('tab10.title')}
                     description={i18next.t('tab10.desc')}>
            <HsftDataGrid
                id={"TAB10"}
                editable={true}
                columns={getTab10Columns}
                dataSource={tab10DataSource}
                onRowValidating={uniqueValidation}
                modifiable={false}
            />
        </ContentPage>
    );
}

export default Tab10Page;
