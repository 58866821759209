import {Column, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import HsftStore from "../../stores/baseStore";
import VykonDropDownBoxComponent from "../../components/utvar-drop-down/VykonDropDownBoxComponent";
import i18next from 'i18next';

const hfstStore = new HsftStore('TAB31S',
    {
        loadMode: "raw",
        cacheRawData: true,
        key: "kod"
    }
);
const tab31SStore = hfstStore.getStore();

const kodEditorOptions = {
    min: 1,
    max: 999,
    format: '###',
    inputAttr: {
        maxLength: 3
    }
};

function getTab52sColumns(): Array<any> {
    return [
        <Column
            key="vyk"
            dataField="vyk"
            caption={i18next.t("tab52s_columns.vyk")}
            dataType="string"
            width={300}
            defaultSortOrder="asc"
            editCellComponent={VykonDropDownBoxComponent}
        >
            <Lookup
                dataSource={tab31SStore}
                displayExpr="kod"
                valueExpr="kod"
            />
            <RequiredRule/>
            <PatternRule
                message={i18next.t("tab52s_columns.message_vykon")}
                pattern={/^\d{1,3}$/}
            />
        </Column>,
        <Column
            key="podvyk"
            dataField="podvyk"
            caption={i18next.t("tab52s_columns.podvyk")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
            editorOptions={kodEditorOptions}
        >
            <RequiredRule/>
            <PatternRule
                message={i18next.t("tab52s_columns.message_podvykon")}
                pattern={/^\d{1,3}$/}
            />
        </Column>,
        <Column
            key="nazev"
            dataField="nazev"
            caption={i18next.t("tab52s_columns.nazev")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>
    ];
}

export {getTab52sColumns};
