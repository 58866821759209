import {Button, Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import i18next from 'i18next';
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";

function getDocumentsViewColumns(onDetailClick: any): Array<any> {
    return [
        <Column
            key="f80"
            dataField="f80"
            caption={i18next.t("doklady.cols.f80")}
            dataType="date"
            defaultSortOrder="desc"
        />,
        <Column
            key="b18"
            dataField="b18"
            caption={i18next.t("doklady.cols.b18")}
            dataType="number"
        />,
        <Column
            key="o06"
            dataField="o06"
            caption={i18next.t("doklady.cols.o06")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="sumr62"
            dataField="sumr62"
            caption={i18next.t("doklady.cols.sumr62")}
            format={{type: 'fixedPoint', precision: 2}}
            dataType="number"
        />,
        <Column
            key="v10"
            dataField="v10"
            caption={i18next.t("doklady.cols.v10")}
            dataType="number"
        />,
        <Column
            key="buttons"
            type={"buttons"}
            caption={i18next.t("doklady.cols.buttons")}
        >
            <Button hint="Detail" icon={"search"} onClick={onDetailClick}/>
        </Column>,
        <Column
            key="v18"
            dataField="v18"
            caption={i18next.t("doklady.cols.v18")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
            visible={false}
        />,
        <Column
            key="z27"
            dataField="z27"
            caption={i18next.t("doklady.cols.z27")}
            dataType="number"
            visible={false}
        />,
        <Column
            key="z28"
            dataField="z28"
            caption={i18next.t("doklady.cols.z28")}
            dataType="number"
            visible={false}
        />,
        <Column
            key="z29"
            dataField="z29"
            caption={i18next.t("doklady.cols.z29")}
            dataType="number"
            visible={false}
        />,
        <Column
            key="o02"
            dataField="o02"
            caption={i18next.t("doklady.cols.o02")}
            dataType="number"
            visible={false}
        />,
        <Column
            key="v33"
            dataField="v33"
            caption={i18next.t("doklady.cols.v33")}
            dataType="number"
            visible={false}
        />,
        <Column
            key="d54"
            dataField="d54"
            caption={i18next.t("doklady.cols.d54")}
            dataType="number"
            visible={false}
        />,
        <Column
            key="e60"
            dataField="e60"
            caption={i18next.t("doklady.cols.e60")}
            dataType="number"
            visible={false}
        />,
        <Column
            key="c79"
            dataField="e79"
            caption={i18next.t("doklady.cols.c79")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
            visible={false}
        />,
        <Column
            key="c80"
            dataField="e80"
            caption={i18next.t("doklady.cols.c80")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
            visible={false}
        />,
        <Column
            key="edo"
            dataField="edo"
            caption={i18next.t("doklady.cols.edo")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
            visible={false}
        />,
    ];
}

export {getDocumentsViewColumns};
