import {Column, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import CustomStore from "devextreme/data/custom_store";
import {tab12sAPI} from "../../api/tab12sAPI";
import ColumnUtils from "../../utils/columnUtils";
import i18next from 'i18next';

function getTab33sColumns(): Array<any> {
    return [
        <Column
            key="ucel"
            dataField="ucel"
            caption={i18next.t("tab33s_columns.ucel")}
            dataType="number"
            width={175}
            defaultSortOrder="asc"
        />,
        <Column
            key="naz"
            dataField="naz"
            caption={i18next.t("tab33s_columns.naz")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />
    ];
}

export {getTab33sColumns};
