import {Column, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import CustomStore from "devextreme/data/custom_store";
import {tab12sAPI} from "../../api/tab12sAPI";
import ColumnUtils from "../../utils/columnUtils";
import i18next from 'i18next';

function getTab12sColumns(): Array<any> {
    return [
        <Column
            key="s12"
            dataField="s12"
            caption={i18next.t("tab12s_columns.s12")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
        />,
        <Column
            key="nazev"
            dataField="nazev"
            caption={i18next.t("tab12s_columns.nazev")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />
    ];
}

export {getTab12sColumns};
