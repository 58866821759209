import React from 'react';
import {jsPDF} from "jspdf";
import {exportDataGrid as pdfExportDataGrid} from "devextreme/pdf_exporter";
import {Workbook} from "exceljs";
import {exportDataGrid as excelExportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver";

export default class GridUtils {

    static gridExport(e: any) {
        // console.log(e);
        if (e.format === 'pdf') {
            const doc = new jsPDF();

            pdfExportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                indent: 1,
            }).then(() => {
                doc.save('Export.pdf');
            });
        } else {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Export');
            excelExportDataGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function (options) {
                    options.excelCell.font = {name: 'Arial', size: 12};
                    options.excelCell.alignment = {horizontal: 'left'};
                }
            }).then(function () {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Export.xlsx');
                    });
            });
        }
    }
}
