import {Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import i18next from 'i18next';
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";

function getCustomersViewColumns(): Array<any> {
    return [
        <Column
            key="ico"
            dataField="o02"
            caption={i18next.t("ic")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
            allowEditing={false}
        />,
        <Column
            key="nazev"
            dataField="o06"
            caption={i18next.t("nazev")}
            dataType="string"
            allowEditing={false}
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
            groupIndex={0}
        />,
        <Column
            key="rok"
            dataField="v12"
            caption={i18next.t("rok")}
            dataType="number"
            width={180}
        />,
        <Column
            key="mesic"
            dataField="v13"
            caption={i18next.t("mesic")}
            dataType="number"
            width={180}
        />,
        <Column
            key="sortiment"
            dataField="d71"
            caption={i18next.t("sortiment")}
            dataType="number"
            width={125}
        />,
        <Column
            key="nazevSortimentu"
            dataField="d71_nazev"
            caption={i18next.t("nazev_sortimentu")}
            dataType="string"
            allowEditing={false}
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="zkrDrev"
            dataField="v57_zkr"
            caption={i18next.t("zkrat_drev")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="nazevDreviny"
            dataField="v57_nazev"
            caption={i18next.t("nazev_dreviny")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="mnozstvi"
            dataField="r62"
            caption={i18next.t("mnozstvi")}
            dataType="number"
            format={{type: 'fixedPoint', precision: 2}}
            width={125}
        />,
    ];
}

export {getCustomersViewColumns};
