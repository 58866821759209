import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './home.scss';
import ContentPage from "../../components/content/content-page/ContentPage";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import HsftStore from "../../stores/baseStore";
import DataSource from "devextreme/data/data_source";
import {LoadOptions} from "devextreme/data/index"
import {getCustomersViewColumns} from "../../definitions/columns/customersViewColumns";
import {
    ColumnChooser,
    ColumnChooserSelection,
    ColumnFixing,
    DataGrid, Editing, Export, FilterRow, Grouping, GroupPanel, HeaderFilter, Paging, SearchPanel,
    Sorting, GroupItem,
    StateStoring, Summary
} from "devextreme-react/data-grid";
import GridUtils from "../../utils/gridUtils";
import Box, {Item} from "devextreme-react/box";
import PieChart, {
    Connector,
    Export as PieExport,
    Label as PieLabel,
    Series as PieSeries,
    Size
} from "devextreme-react/pie-chart";
import {Chart, Format, Series} from "devextreme-react/chart";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {ButtonTypes} from "devextreme-react/button"
import {DateBoxTypes} from "devextreme-react/date-box"
import {useTranslation} from 'react-i18next';
import {ScrollView} from "devextreme-react/scroll-view";
import {Label} from "devextreme-react/form";

const exportFormats = ['xlsx'];

const getFormattedDate = (date: Date) => {
    return date.toISOString().split('T')[0];
};

const storeHSFTDodavkyOdberateleCelkem = new HsftStore('dodavky-odberatele-celkem');
const storeDodavkyOdberateleCelkem = storeHSFTDodavkyOdberateleCelkem.getStore();
const DodavkyOdberateleCelkemDS = new DataSource({
    store: storeDodavkyOdberateleCelkem
});

const storeHSFTDodavkyOdberatele = new HsftStore('dodavky-odberatele');
const storeDodavkyOdberatele = storeHSFTDodavkyOdberatele.getStore();
const DodavkyOdberateleDS = new DataSource({
    store: storeDodavkyOdberatele
});

const storeHSFTDodavkyObdobi = new HsftStore('dodavky-obdobi');
const storeDodavkyObdobi = storeHSFTDodavkyObdobi.getStore();
const DodavkyObdobiDS = new DataSource({
    store: storeDodavkyObdobi
});

const summaryValueFormat = {type: 'fixedPoint', precision: 2};

export default function Home() {
    const {t} = useTranslation();


    const toolbarTexts = {
        title: t('Přehledy za období od:'),
        do: t('until'),
        pieChartTitle: t('Dodávky dříví za odběratele'),
        chartTitle: t('Dodávky dříví po měsících'),
        groupItemDisplayFormat: t('Celkem: {0} m³'),
        groupItemFooterDisplayFormat: t('∑{0}')
    };

    const gridRef = useRef<any>(null);
    const [dateFrom, setDateFrom] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
    const [dateUntil, setDateUntil] = useState<Date>(new Date());

    const getDateParams = useCallback((loadOptions: LoadOptions) => {
        loadOptions.userData = {
            datumOd: getFormattedDate(dateFrom),
            datumDo: getFormattedDate(dateUntil)
        };
    }, [dateFrom, dateUntil]);

    useEffect(() => {
        const handleLoading = (loadOptions: LoadOptions) => {
            getDateParams(loadOptions);
        };

        storeDodavkyOdberateleCelkem.on('loading', handleLoading);
        storeDodavkyOdberatele.on('loading', handleLoading);
        storeDodavkyObdobi.on('loading', handleLoading);

        return () => {
            storeDodavkyOdberateleCelkem.off('loading', handleLoading);
            storeDodavkyOdberatele.off('loading', handleLoading);
            storeDodavkyObdobi.off('loading', handleLoading);
        };
    }, [getDateParams]);

    // storeDodavkyOdberateleCelkem.on('loading', getDateParams);
    // storeDodavkyOdberatele.on('loading', getDateParams);
    // storeDodavkyObdobi.on('loading', getDateParams);

    const onExporting = useCallback((e: any) => {
        GridUtils.gridExport(e);
    }, []);

    const dateFromChanged = useCallback((e: DateBoxTypes.ValueChangedEvent) => {
        setDateFrom(e.value);
    }, []);

    const dateBoxFromOptions = useMemo(() => ({
        onValueChanged: dateFromChanged,
        value: dateFrom,
        type: "date"
    }), [dateFrom, dateFromChanged]);

    const dateUntilChanged = useCallback((e: DateBoxTypes.ValueChangedEvent) => {
        setDateUntil(e.value);
    }, []);

    const dateBoxUntilOptions = useMemo(() => ({
        onValueChanged: dateUntilChanged,
        value: dateUntil,
        type: "date"
    }), [dateUntil, dateUntilChanged]);

    const setDate = useCallback((e: ButtonTypes.ClickEvent) => {
        // DodavkyOdberateleDS.load();
        DodavkyOdberateleCelkemDS.load();
        DodavkyObdobiDS.load();
        gridRef.current.instance().refresh(true);
    }, [DodavkyOdberateleDS, DodavkyOdberateleCelkemDS, DodavkyObdobiDS, gridRef]);

    const searchButtonOptions = useMemo(() => ({
        icon: 'search',
        onClick: setDate
    }), [setDate]);

    return (
        <ContentPage title={""} description={""}>
            <Toolbar>
                <ToolbarItem
                    location="before"
                    text={toolbarTexts.title}
                >
                </ToolbarItem>
                <ToolbarItem
                    widget="dxDateBox"
                    location="before"
                    options={dateBoxFromOptions}
                >
                </ToolbarItem>
                <ToolbarItem
                    location="before"
                    // text={"do"}
                    text={toolbarTexts.do}
                >
                </ToolbarItem>
                <ToolbarItem
                    widget="dxDateBox"
                    location="before"
                    options={dateBoxUntilOptions}
                >
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    location="before"
                    options={searchButtonOptions}
                >
                </ToolbarItem>
            </Toolbar>
            <ScrollView height={"100%"}>
            <Box direction={"col"} height={"100%"}>
                <Item baseSize={400} ratio={1}>
                    <Box direction="row"
                         width="100%"
                         align="center"
                         crossAlign="stretch">
                        <Item ratio={1}>
                            {/*<div className={'chart-container'}>*/}
                            <PieChart
                                id="pie"
                                dataSource={DodavkyOdberateleCelkemDS}
                                palette="Bright"
                                title={toolbarTexts.pieChartTitle}
                                // onPointClick={pointClickHandler}
                                // onLegendClick={legendClickHandler}
                            >
                                <PieSeries argumentField="o06" valueField="r62">
                                    <PieLabel
                                        visible={true}
                                        format={summaryValueFormat}
                                        // customizeText={formatPieLabel}
                                    >
                                        <Connector visible={true} width={1}/>
                                    </PieLabel>
                                </PieSeries>

                                <Size width={500}/>
                                <PieExport enabled={true}/>
                            </PieChart>
                            {/*</div>*/}
                        </Item>
                        <Item ratio={1}>
                            <Chart
                                id="chart"
                                dataSource={DodavkyObdobiDS}
                                title={toolbarTexts.chartTitle}
                            >
                                <Series
                                    valueField="r62"
                                    argumentField="obdobi"
                                    name={toolbarTexts.chartTitle}
                                    showInLegend={false}
                                    type="bar"

                                    color="#ffaa66">
                                    <Label visible={true}>
                                        <Format type="fixedPoint" precision={2} />
                                    </Label>
                                </Series>
                                <Export enabled={true}/>
                            </Chart>
                        </Item>
                    </Box>
                </Item>
                <Item ratio={6} baseSize={500}>
                    <DataGrid
                        height={"100%"}
                        ref={gridRef}
                        id="grid-container"
                        dataSource={DodavkyOdberateleDS}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        repaintChangesOnly={true}
                        onExporting={onExporting}
                    >
                        <StateStoring enabled={true}
                                      type="localStorage"
                                      storageKey={"customersView"}/>
                        <ColumnFixing enabled={true}/>
                        <ColumnChooser enabled={true}
                                       mode="select"
                                       height={450}
                        >
                            <ColumnChooserSelection
                                allowSelectAll={true}
                                selectByClick={true}/>
                        </ColumnChooser>
                        <Sorting mode={"multiple"}/>
                        <SearchPanel visible={true}/>
                        <FilterRow visible={true}/>
                        <HeaderFilter visible={true}/>
                        <Export enabled={true} formats={exportFormats}/>
                        <Grouping
                            contextMenuEnabled={false}
                            autoExpandAll={false}
                        />
                        <GroupPanel visible={false}/>
                        <Paging
                            enabled={false}
                        />
                        <Summary>
                            <GroupItem
                                column={"r62"}
                                // valueFormat="number"
                                summaryType="sum"
                                displayFormat={toolbarTexts.groupItemDisplayFormat}
                                valueFormat={summaryValueFormat}
                                // showInGroupFooter={true}
                            />
                            <GroupItem
                                column={"r62"}
                                // valueFormat="number"
                                summaryType="sum"
                                displayFormat={t('∑{0}')}
                                valueFormat={summaryValueFormat}
                                showInGroupFooter={true}
                            />
                        </Summary>
                        {getCustomersViewColumns().map((column: any) => column)}
                    </DataGrid>
                </Item>
            </Box>
            </ScrollView>
        </ContentPage>
    );
}
