import React, {useCallback, useState} from 'react';
import './tab13s.scss';
import DataSource from 'devextreme/data/data_source';
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import HsftStore from "../../stores/baseStore";
import {getTab13sColumns} from "../../definitions/columns/tab13sColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import i18next from 'i18next';

function uniqueValidation(e: any) {
    if (e.isValid) {
        let id = 0;
        if ((e.oldData) && ('rowid' in e.oldData)) {
            id = e.oldData.rowid;
        }
        let updatedData: any = {};
        if (e.oldData) {
            updatedData = {...e.oldData, ...e.newData}
        } else {
            updatedData = e.newData;
        }

        for (const item of tab13SDataSource.items()) {
            if ((item.rowid !== id) && (item.utvar === updatedData.utvar) && (item.usek === updatedData.usek)) {
                e.isValid = false;
                e.errorText = i18next.t("tab13.error.unik_usek");
            }
        }
    }
}

const hsftStore = new HsftStore('TAB13S');

const tab13SDataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab13sPage() {
    return (
        <ContentPage title={i18next.t("tab13.title")}
                     description={i18next.t("tab13.desc")}>
            <HsftDataGrid
                id={"TAB13S"}
                editable={true}
                columns={getTab13sColumns}
                dataSource={tab13SDataSource}
                onRowValidating={uniqueValidation}
            />
        </ContentPage>
    );
}

export default Tab13sPage;
