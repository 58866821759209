import {Column, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import HsftStore from "../../stores/baseStore";
import i18next from 'i18next';

const hfstStore = new HsftStore('TAB12S',
    {
        loadMode: "raw",
        cacheRawData: true,
        key: "s12"
    }
);
const tab12SStore = hfstStore.getStore();

const kodEditorOptions = {
    min: 1,
    max: 999,
    format: '###',
    inputAttr: {
        maxLength: 3
    }
};

function getTab29zColumns(): Array<any> {
    return [
        <Column
            key="utvar"
            dataField="utvar"
            caption={i18next.t("tab29z_columns.utvar")}
            dataType="string"
            width={300}
            defaultSortOrder="asc"
            editCellComponent={UtvarDropDownBoxComponent}
        >
            <Lookup
                dataSource={tab12SStore}
                displayExpr="s12"
                valueExpr="s12"
            />
            <RequiredRule/>
            <PatternRule
                message={i18next.t("tab29z_columns.message_utvar")}
                pattern={/^\d{1,3}$/}
            />
        </Column>,
        <Column
            key="sklad"
            dataField="sklad"
            caption={i18next.t("tab29z_columns.sklad")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
            editorOptions={kodEditorOptions}
        >
            <RequiredRule/>
            <PatternRule
                message={i18next.t("tab29z_columns.message_usek")}
                pattern={/^\d{1,3}$/}
            />
        </Column>,
        <Column
            key="nazev"
            dataField="nazev"
            caption={i18next.t("tab29z_columns.nazev")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>
    ];
}

export {getTab29zColumns};
