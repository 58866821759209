import React, { useMemo, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './UserPanel.scss';
import type { UserPanelProps } from '../../types';
import i18next from 'i18next';

export default function UserPanel({ menuMode }: UserPanelProps) {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const navigateToProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);
  const menuItems = useMemo(() => ([
    // {
    //   text: 'Profile',
    //   icon: 'user',
    //   onClick: navigateToProfile
    // },
    {
      text: i18next.t("user_panel.odhlasit"),
      icon: 'runner',
      onClick: signOut
    }
  ]), [navigateToProfile, signOut]);
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        {/*<div className={'image-container'}>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      background: `url(${user!.avatarUrl}) no-repeat #fff`,*/}
        {/*      backgroundSize: 'cover'*/}
        {/*    }}*/}
        {/*    className={'user-image'} />*/}
        {/*</div>*/}
        <div className={'user-name'}>{user!.firstName} {user!.lastName}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
