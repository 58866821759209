import React, {useCallback, useState} from 'react';
import './tab12s.scss';
import DataSource from 'devextreme/data/data_source';
import HsftStore from "../../stores/baseStore";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab12sColumns} from "../../definitions/columns/tab12sColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import {getTab57Columns} from "../../definitions/columns/tab57Columns";
import i18next from 'i18next';

const hsftStore = new HsftStore('TAB12S');

const tab12SDataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab12sPage() {

    return (
        <ContentPage title={i18next.t("TAB12S.title")} description={i18next.t("TAB12S.description")}>
            <HsftDataGrid
                id={"TAB12S"}
                editable={true}
                columns={getTab12sColumns}
                dataSource={tab12SDataSource}
            />
        </ContentPage>
    );
}

export default Tab12sPage;
