import {Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";
import i18next from 'i18next';
import PartnerDropDownBoxComponent from "../../components/utvar-drop-down/PartnerDropDownBoxComponent";

const kodEditorOptions = {
    min: 1,
    max: 999,
    format: '###',
    inputAttr: {
        maxLength: 3
    }
};

const hfstStoreS73S = new HsftStore('TAB73S',
    {
        loadMode: "raw",
        cacheRawData: true,
        key: "x18"
    }
);
const tab73SStore = hfstStoreS73S.getStore();

function getTab79RZColumns(): Array<any> {
    return [
        <Column
            key="c79"
            dataField="c79"
            caption={i18next.t("tab79rz_columns.c79")}
            dataType="string"
            width={150}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="d74"
            dataField="d74"
            caption={i18next.t("tab79rz_columns.d74")}
            dataType="string"
            width={300}
            defaultSortOrder="asc"
            editCellComponent={PartnerDropDownBoxComponent}
        >
            <Lookup
                dataSource={tab73SStore}
                displayExpr="x18"
                valueExpr="x18"
            />
        </Column>,
        <Column
            key="popis"
            dataField="popis"
            caption={i18next.t("tab79rz_columns.popis")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="maxnakl"
            dataField="maxnakl"
            caption={i18next.t("tab79rz_columns.maxnakl")}
            dataType="number"
        >
        </Column>,
        <Column
            key="ruka"
            dataField="ruka"
            caption={i18next.t("tab79rz_columns.ruka")}
            dataType="boolean"
        >,
        </Column>,
        <Column
            key="prives"
            dataField="prives"
            caption={i18next.t("tab79rz_columns.prives")}
            dataType="boolean"
        >,
        </Column>
,
        // <Column
        //     key="sdt"
        //     dataField="sdt"
        //     caption="Skupina"
        // >
        //     <RequiredRule />
        // </Column>,
    ];
}

export {getTab79RZColumns};
