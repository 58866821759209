import {checkStatus, parseJSON} from "./baseAPI";
import appInfo from '../app-info';
import DevExpress from "devextreme";
import { LoadOptions } from "devextreme/data/index"
import i18next from "i18next";

function objectToQueryString(params: Record<string, string | number | boolean>): string {
    return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(String(params[key]))}`)
        .join('&');
}

// Funkce pro vytvoření API pro specifický číselník
function createCatalogAPI(catalogName: string) {
    let url = `${appInfo.apiURL}/${catalogName}`;

    if (catalogName === 'uzivatele') {
        url = `${appInfo.uzivateleURL}`;
    } else if (catalogName === 'qr') {
        url = `${appInfo.uzivateleURL}/qr`;
    } else if (catalogName === 'konfigurace') {
        url = `${appInfo.konfiguraceURL}`;
    } else if ((catalogName === 'doklady')
        || (catalogName === 'doklady-detail')
        || (catalogName === 'dodavky-odberatele-celkem')
        || (catalogName === 'dodavky-odberatele')
        || (catalogName === 'dodavky-obdobi')) {
        url = `${appInfo.dataURL}/${catalogName}`;
        // url = `${appInfo.dataURL}/${catalogName}?datumOd=2023-01-01&datumDo=2024-12-31`;
    }

    return {
        load(loadOptions?: LoadOptions) {
            const token = localStorage.getItem('token');

            let getUrl = url;
            if (loadOptions && loadOptions.userData) {
                const queryString = objectToQueryString(loadOptions.userData);
                getUrl += '?' + queryString;
            }

            return fetch(getUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(checkStatus)
                .then(parseJSON)
                .catch((error: TypeError) => {
                    throw new Error(i18next.t("catalogAPI.loadError"));
                });
        },
        update(key: string, values: any) {
            // double encoding for IIS and MVC framework
            key = encodeURIComponent(key);
            key = encodeURIComponent(key);
            const token = localStorage.getItem('token');
            let updateURL = `${url}/${key}`;
            if (catalogName === 'konfigurace') {
                updateURL = `${url}`;
            }

            return fetch(`${updateURL}`, {
                method: 'PUT',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(checkStatus)
                .then(parseJSON)
                .catch((error: TypeError) => {
                    throw new Error(i18next.t("catalogAPI.updateError"));
                });
        },
        insert(values: any) {
            const token = localStorage.getItem('token');
            return fetch(`${url}`, {
                method: 'PUT',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(checkStatus)
                .then(parseJSON)
                .catch((error: TypeError) => {
                    throw new Error(i18next.t("catalogAPI.insertError"));
                });
        },
        remove(key: string) {
            const token = localStorage.getItem('token');
            // double encoding for IIS and MVC framework
            key = encodeURIComponent(key);
            key = encodeURIComponent(key);
            return fetch(`${url}/${key}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(checkStatus)
                .catch((error: TypeError) => {
                    throw new Error(i18next.t("catalogAPI.deleteError"));
                });
        }
    };
}

export {createCatalogAPI}; // Exportujeme funkci, pokud chceme vytvářet další instance pro jiné číselníky
