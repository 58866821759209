import {Column, ILookupProps, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import UtvarDropDownBoxComponent from "../../components/utvar-drop-down/UtvarDropDownBoxComponent";
import React from "react";
import ColumnUtils from "../../utils/columnUtils";
import HsftStore from "../../stores/baseStore";
import {CustomRule} from "devextreme-react/form";
import i18next from 'i18next';

const kodEditorOptions = {
    min: 1,
    max: 999,
    format: '###',
    inputAttr: {
        maxLength: 3
    }
};

function getTabtlsColumns(): Array<any> {
    return [
        <Column
            key="jakost"
            dataField="jakost"
            caption={i18next.t("tabtls_columns.jakost")}
            dataType="string"
            width={150}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="prum_od"
            dataField="prum_od"
            caption={i18next.t("tabtls_columns.prum_od")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="prum_do"
            dataField="prum_do"
            caption={i18next.t("tabtls_columns.prum_do")}
            dataType="number"
            width={150}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
        <Column
            key="tl_st"
            dataField="tl_st"
            caption={i18next.t("tabtls_columns.tl_st")}
            dataType="string"
            width={150}
            defaultSortOrder="asc"
        >
            <RequiredRule/>
        </Column>,
    ];
}

export {getTabtlsColumns};
