import {Button, Column, Lookup, PatternRule, RequiredRule} from "devextreme-react/data-grid";
import React, {useCallback} from "react";
import ColumnUtils from "../../utils/columnUtils";
import i18next from 'i18next';

function getUsersColumns(onQRClick: any): Array<any> {

    return [
        <Column
            key="cisloProfilu"
            dataField="cisloProfilu"
            caption={i18next.t("users_columns.cisloProfilu")}
            width={150}
            dataType="number"
        />,
        <Column
            key="login"
            dataField="login"
            caption={i18next.t("users_columns.login")}
            width={250}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="jmeno"
            dataField="jmeno"
            caption={i18next.t("users_columns.jmeno")}
            width={300}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="prijmeni"
            dataField="prijmeni"
            caption={i18next.t("users_columns.prijmeni")}
            dataType="string"
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="otisk"
            dataField="otisk"
            caption={i18next.t("users_columns.otisk")}
            dataType="boolean"
            width={125}
            sortingMethod={ColumnUtils.sortStringsConsideringCulture}
        />,
        <Column
            key="buttons"
            type={"buttons"}
            caption="QR"
        >
            <Button hint="QR" icon={"search"} onClick={onQRClick}/>
        </Column>
    ];
}

export {getUsersColumns};
