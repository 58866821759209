import React from 'react';
import './TAB57.scss';
import HsftStore from "../../stores/baseStore";
import DataSource from "devextreme/data/data_source";
import HsftDataGrid from "../../components/hsft/hsft-data-grid/HsftDataGrid";
import {getTab57Columns} from "../../definitions/columns/tab57Columns";
import {getTab29zColumns} from "../../definitions/columns/tab29zColumns";
import ContentPage from "../../components/content/content-page/ContentPage";
import i18next from 'i18next';

const hsftStore = new HsftStore('TAB57');
const tab57DataSource = new DataSource({
    store: hsftStore.getStore()
});

function Tab57Page() {
    return (
        <ContentPage title={i18next.t("tab57.title")} description={i18next.t("tab57.desc")} >
            <HsftDataGrid
                id={"TAB57"}
                editable={true}
                columns={getTab57Columns}
                dataSource={tab57DataSource}
            />
        </ContentPage>
    );
}

export default Tab57Page;
